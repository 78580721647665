import request from './request'

export default {
  fetchArticleList(kind, params) {
    return request.get(`/kinds/${kind}/articles`, { params })
  },
  fetchSpecArticleList(params) {
    return request.get(`/special-columns`, { params })
  },
  fetchUserSpecColumnList(params) {
    return request.get(`/special-columns`, { params })
  },
  fetchUserStarSpecColumnList(params) {
    return request.get(`/user/stars/special-columns`, { params })
  },
  fetchSpecColumnInfo(id, params) {
    return request.get(`/special-columns/${id}`, { params })
  },
  fetchMyArticleList(params) {
    return request.get(`/user/articles`, { params })
  },
  fetchMyFavorArticleList(params) {
    return request.get(`/user/stars/articles`, { params })
  },
  fetchArticleInfo(kind, id) {
    return request.get(`/kinds/${kind}/articles/${id}`)
  },
  addArticleInfo(kind, payload) {
    return request.post(`/kinds/${kind}/articles`, payload)
  },
  updateArticleInfo(kind, id, payload) {
    return request.put(`/kinds/${kind}/articles/${id}`, payload)
  },
  cancelArticleStatus(kind, id, payload) {
    return request.put(`/kinds/${kind}/articles/${id}/cancel-review`, payload)
  },
  deleteArticleInfo(kind, id) {
    return request.delete(`/kinds/${kind}/articles/${id}`)
  },
  fetchTagList(params) {
    return request.get(`/tags`, { params })
  },
  addNewTag(params) {
    return request.post(`/tags`, params)
  },
  fetchLibraryCategoryList(params) {
    return request.get(`/library-categories`, { params })
  },
  updateArticleStarStatus(id, payload) {
    return request.post(`/articles/${id}/star`, payload)
  },
  updateArticleThumbupStatus(id, payload) {
    return request.post(`/articles/${id}/thumbs-up`, payload)
  },
  updateArticlePV(id, payload) {
    return request.post(`/articles/${id}/pv`, payload)
  },
  addSpecColumnInfo(payload) {
    return request.post(`/special-columns`, payload)
  },
  updateSpecColumnInfo(id, payload) {
    return request.put(`/special-columns/${id}`, payload)
  },
  updateSpecColumnStarStatus(id, payload) {
    return request.post(`/special-columns/${id}/star`, payload)
  },
}
