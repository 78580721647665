import Vue from 'vue'
import _ from 'lodash'

import Loading from './index.vue'

const LoadingConstructor = Vue.extend(Loading)

let globalLoadingIsMounted = false
let globalLoadingInstance = null

const appLoading = options => {
  if (Vue.prototype.$isServer) return

  if (globalLoadingIsMounted) return globalLoadingInstance

  const vm = new LoadingConstructor({
    data: _.assign(options, {
      isFullscreen: true,
      zIndex: 10000,
      isVisible: true,
    }),
  }).$mount()

  globalLoadingIsMounted = true
  globalLoadingInstance = vm

  vm.$on('destroyed', () => {
    globalLoadingIsMounted = false
    globalLoadingInstance = null
  })

  vm.close = () => {
    if (vm.$el) {
      vm.$el.parentNode && vm.$el.parentNode.removeChild(vm.$el)
    }
    globalLoadingIsMounted = false
    globalLoadingInstance = null
  }

  document.body.appendChild(vm.$el)

  return vm
}

appLoading.install = Vue => {
  Vue.prototype.$appLoading = appLoading
}

export default appLoading
