<template>
  <a-upload
    class="file-uploader"
    :action="host"
    :data="uploadPayload"
    :show-file-list="isShowFileList"
    :file-list="fileList"
    :before-upload="handleBeforeUpload"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
    :on-error="handleFail"
    :headers="{ Authorization: `Bearer ${token}` }"
  >
    <slot>
      <a-button size="small">{{ uploadText }}</a-button>
    </slot>
  </a-upload>
</template>

<script>
import _ from 'lodash'
import uploadService from '@/services/upload'
import { getToken } from '@/services/token'

const tasks = {}
const noop = () => {}

export default {
  name: 'FileUpload',
  props: {
    fileList: {
      type: Array,
      default: () => {
        return []
      },
    },
    fileType: {
      type: String,
      default: 'file',
    },
    uploadText: {
      type: String,
      default: '点击上传',
    },
    beforeUpload: {
      type: Function,
      default: noop,
    },
    limit: {
      type: Number,
      default: 1,
    },
    isShowFileList: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 50000, // kb
    },
  },
  data() {
    return {
      token: getToken(),
      host: '',
      keyPrefix: '',
      assetsPrefix: '',
      certificate: {},
      fileKey: '',
      imgUrl: '',
      loadingInstance: null,
    }
  },
  computed: {
    accept() {
      const supportTypes = {
        image: 'image/gif,image/jpg,image/jpeg,image/png',
        video: 'video/mp4',
        file: '.pdf,image/gif,image/jpg,image/jpeg,image/png',
      }

      return supportTypes[this.fileType] || `${this.fileType}`
    },
    uploadPayload() {
      return _.assign({}, this.certificate, {
        key: this.fileKey,
      })
    },
  },
  watch: {
    fileList(newValue) {
      this.assignURL(newValue)
    },
  },
  created() {
    this.assignURL(this.fileList)
  },
  methods: {
    checkSize(file) {
      return this.maxSize * 1024 > file.size
    },
    handleBeforeUpload(file) {
      if (!this.checkSize(file)) {
        this.$message.error(`文件体积不能超过 ${this.maxSize} kb.`)
        return false
      }

      this.loadingInstance = this.$appLoading()

      return new Promise((resolve, reject) => {
        const result = this.beforeUpload()

        if (result === false) {
          reject(new Error('No result'))
          return
        }

        const action = result && result.then ? result : Promise.resolve(result)

        action
          .then(() => {
            return uploadService.getUploadConfig()
          })
          .then(uploadConfig => {
            this.host = `${window.location.protocol}//${uploadConfig.host}`
            this.keyPrefix = uploadConfig.keyPrefix
            this.assetsPrefix = uploadConfig.assetsPrefix
            this.certificate = uploadConfig.certificate
            this.fileKey = `${this.keyPrefix}${Date.now()}.${_.last(
              file.name.split('.')
            )}`

            tasks[file.uid] = `${this.assetsPrefix}/${this.fileKey}`

            resolve()
          })
          .catch(err => {
            this.$message.error(err.message)
            reject(new Error('Upload error'))
          })
      })
    },
    handleRemove(file) {
      const fileList = _.filter(this.fileList, item => item.path !== file.path)

      this.$emit('change', fileList)
      this.$emit('update:fileList', fileList)
    },
    handleSuccess(response, file) {
      let fileList = _.slice(this.fileList)

      const assetsURL = tasks[file.uid]

      if (!assetsURL) {
        this.$message.error('图片地址错误，请重新上传。')
        return
      }

      fileList.push({
        filename: file.name,
        originalName: file.name,
        url: assetsURL,
        path: assetsURL,
      })

      if (fileList.length > this.limit) {
        fileList = _.tail(fileList)
      }

      this.imgUrl = assetsURL

      this.$emit('success', fileList)
      this.$emit('change', fileList)
      this.$emit('update:fileList', fileList)
      this.loadingInstance.close()
    },
    handleFail(err) {
      let message = err.message

      try {
        const data = JSON.parse(err.message)

        if (data.message) {
          message = data.message
        }
      } catch (err) {
        message = '上传失败'
      }

      this.$message.error(message)

      this.$emit('fail', new Error(message), this.files)
      this.loadingInstance.close()
    },
    assignURL(target) {
      _.forEach(target, item => {
        if (!item.url) {
          _.assign(item, {
            url: item.path,
          })
        }
      })
      const file = _.last(target)
      this.imgUrl = file && file.url
    },
  },
}
</script>

<style lang="scss" scoped>
.file-uploader {
  display: inline-block;
  margin: 0 10px;
}
</style>
