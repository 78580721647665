import Vue from 'vue'
import _ from 'lodash'

export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    next()
    if (process.client) {
      try {
        if (window._hmt) {
          if (to.path) {
            window._hmt.push(['_trackPageview', to.fullPath])
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error)
      }
    }
  })
  app.router.afterEach((to, from) => {
    // Wait for fixPrepatch + $data updates
    Vue.nextTick(() => {
      if (process.client) {
        _.delay(() => {
          window.objectFitPolyfill()
        }, 1000)
      }
    })
  })
}
