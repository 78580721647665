<template>
  <div v-if="isVisible" class="app-loading app-loading--dark">
    <!-- <img src="~/assets/loading.gif" alt="" class="app-loading__img" /> -->
    <a-spin size="large" />
  </div>
</template>
<script>
export default {
  name: 'AppLoading',
  data() {
    return {
      isFullscreen: true,
      isVisible: false,
    }
  },
  methods: {
    start() {
      this.isVisible = true
    },
    finish() {
      this.isVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.app-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 9999;

  &--dark {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &--fullscreen {
    position: fixed;
  }

  &__img {
    width: 228px;
    height: 180px;
    object-fit: cover;
  }
}
</style>
