import axios from 'axios'
import { getToken, setToken, removeToken, removeHistoryKeys } from './token'

const isInProd = process.env.NODE_ENV === 'production'

const instance = axios.create()

const configurations = {
  baseURL: `${isInProd && process.client ? '' : process.env.API_HOST}/api/v1`,
  useAccessToken: true,
  getAccessToken() {
    return getToken()
  },
}

instance.defaults.baseURL = configurations.baseURL

instance.interceptors.request.use(
  config => {
    if (configurations.useAccessToken) {
      const accessToken = configurations.getAccessToken()

      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken
      } else {
        // eslint-disable-next-line no-console
        console.warn('[Request middleware] No access token is found.')
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (response, s) => {
    // X-Refresh-Token
    const refreshAccessToken = response.headers['x-refresh-token']
    if (refreshAccessToken) {
      setToken(refreshAccessToken)
    }
    return Promise.resolve(response.data)
  },
  err => {
    if (err.response) {
      if (err.response.status === 401 || err.response.status === 403) {
        // eslint-disable-next-line no-console
        console.error('Status 401')

        // Redirect to login page or somewhere.

        if (process.client) {
          if (window.location.pathname !== '/user/login') {
            window.location.href = '/user/login'
          }
        }
        removeToken()
        removeHistoryKeys()
      }

      err.response.data =
        typeof err.response.data === 'object' ? err.response.data : {}

      err.response.data.status = err.response.status

      if (!err.response.data.message) {
        err.response.data.message = err.response.statusText
      }

      return Promise.reject(err.response.data)
    } else if (err.request) {
      return Promise.reject(new Error('网络开小差了~'))
    } else {
      return Promise.reject(err)
    }
  }
)

export default instance
