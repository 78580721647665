export default function(to, from, savedPosition) {
  const nuxt = window.$nuxt
  nuxt.$emit('triggerScroll')

  return new Promise((resolve, reject) => {
    nuxt.$once('triggerScroll', () => {
      if (savedPosition) {
        nuxt.$nextTick(() => {
          resolve(savedPosition)
        })
      } else {
        if (to.hash) {
          // 如果目标页面的url有锚点,  则滚动至锚点所在的位置
          // const position = { selector: to.hash }
          // nuxt.$nextTick(() => {
          //   resolve(position)
          // })
        } else {
          let position = { x: 0, y: 0 }
          // 目标页面子组件少于两个
          if (to.matched.length < 2) {
            // 滚动至页面顶部
            position = { x: 0, y: 0 }
          } else if (
            to.matched.some(r => r.components.default.options.scrollToTop)
          ) {
            // 如果目标页面子组件中存在配置了scrollToTop为true
            position = { x: 0, y: 0 }
          }

          nuxt.$nextTick(() => {
            resolve(position)
          })
        }
      }
    })
  })
}
