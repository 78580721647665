<template>
  <div :class="`app-metting-card app-metting-card-${size}`">
    <img
      v-lazy="compressImg(thumbnail, '712')"
      class="app-metting-card-thumbnail image-radius"
      data-object-fit="cover"
    />
    <div class="app-metting-card-title">{{ title }}</div>
    <div class="app-metting-card-address">{{ address }}</div>
    <div class="app-metting-card-time">{{ time }}</div>
  </div>
</template>

<script>
export default {
  name: 'AppMettingCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    address: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
.app-metting-card {
  position: relative;
  display: inline-block;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  width: 100%;
  height: 230px;
  margin: 0 0 40px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
  // &.app-metting-card-large {
  // width: 644px;
  // height: 230px;
  // }
  // &:hover {
  // }
  &-thumbnail {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }
  &-title {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 56px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 56px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    @include text-ellipsis(1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &-address {
    display: none;
  }
  &-time {
    display: none;
  }
}

@media (max-width: 768px) {
  .app-metting-card {
    // width: 160px;
    height: auto;
    margin: 0 0 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    &.app-metting-card-large {
      // width: 160px;
      height: auto;
    }
    &-thumbnail {
      width: 100%;
      height: 100px;
      @include object-fit(cover);
    }
    &-title {
      position: static;
      left: auto;
      bottom: auto;
      width: 100%;
      height: 38px;
      font-size: 13px;
      font-weight: 600;
      line-height: 1.5;
      color: #333;
      background-color: transparent;
      text-align: left;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 0 10px;
      @include text-ellipsis(2);
    }
    &-address {
      display: block;
      height: 36px;
      padding: 0 10px;
      color: #666;
      font-size: 12px;
      @include text-ellipsis(2);
    }
    &-time {
      display: block;
      padding: 0 10px;
      color: #999;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
}
</style>
