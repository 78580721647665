import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6da6a86d = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4cbfe51e = () => interopDefault(import('../pages/edit-info.vue' /* webpackChunkName: "pages/edit-info" */))
const _400efb34 = () => interopDefault(import('../pages/edit-info/index.vue' /* webpackChunkName: "pages/edit-info/index" */))
const _df1f7828 = () => interopDefault(import('../pages/edit-info/apply-doctor.vue' /* webpackChunkName: "pages/edit-info/apply-doctor" */))
const _2db0213b = () => interopDefault(import('../pages/edit-info/apply-info.vue' /* webpackChunkName: "pages/edit-info/apply-info" */))
const _4241331f = () => interopDefault(import('../pages/edit-info/change-phone.vue' /* webpackChunkName: "pages/edit-info/change-phone" */))
const _ba2048a4 = () => interopDefault(import('../pages/edit-info/change-pwd.vue' /* webpackChunkName: "pages/edit-info/change-pwd" */))
const _5562ebee = () => interopDefault(import('../pages/find.vue' /* webpackChunkName: "pages/find" */))
const _032c5868 = () => interopDefault(import('../pages/find/column.vue' /* webpackChunkName: "pages/find/column" */))
const _42499696 = () => interopDefault(import('../pages/find/library.vue' /* webpackChunkName: "pages/find/library" */))
const _1e9bd8c3 = () => interopDefault(import('../pages/find/top-person.vue' /* webpackChunkName: "pages/find/top-person" */))
const _a50e07bc = () => interopDefault(import('../pages/find/top-person-quarter.vue' /* webpackChunkName: "pages/find/top-person-quarter" */))
const _38af0d9b = () => interopDefault(import('../pages/find/_id/article.vue' /* webpackChunkName: "pages/find/_id/article" */))
const _ab855e0c = () => interopDefault(import('../pages/join.vue' /* webpackChunkName: "pages/join" */))
const _1c100744 = () => interopDefault(import('../pages/meeting/index.vue' /* webpackChunkName: "pages/meeting/index" */))
const _f6ed5674 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _a4959e3c = () => interopDefault(import('../pages/personal-center.vue' /* webpackChunkName: "pages/personal-center" */))
const _77ce7a9e = () => interopDefault(import('../pages/personal-center/attention.vue' /* webpackChunkName: "pages/personal-center/attention" */))
const _183b76d3 = () => interopDefault(import('../pages/personal-center/column.vue' /* webpackChunkName: "pages/personal-center/column" */))
const _20eb704c = () => interopDefault(import('../pages/personal-center/message.vue' /* webpackChunkName: "pages/personal-center/message" */))
const _1c06eeb9 = () => interopDefault(import('../pages/personal-center/meeting/_id.vue' /* webpackChunkName: "pages/personal-center/meeting/_id" */))
const _367c03a4 = () => interopDefault(import('../pages/personal-center/user-info/_id.vue' /* webpackChunkName: "pages/personal-center/user-info/_id" */))
const _060da726 = () => interopDefault(import('../pages/personal-center/weibo/_id.vue' /* webpackChunkName: "pages/personal-center/weibo/_id" */))
const _0ece3d8d = () => interopDefault(import('../pages/personal-center/_id.vue' /* webpackChunkName: "pages/personal-center/_id" */))
const _4b0df745 = () => interopDefault(import('../pages/search-result/index.vue' /* webpackChunkName: "pages/search-result/index" */))
const _242fb1ca = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _7844a991 = () => interopDefault(import('../pages/user/forget.vue' /* webpackChunkName: "pages/user/forget" */))
const _64ab5075 = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _8b4e0f72 = () => interopDefault(import('../pages/user/register.vue' /* webpackChunkName: "pages/user/register" */))
const _7306a880 = () => interopDefault(import('../pages/vip/index.vue' /* webpackChunkName: "pages/vip/index" */))
const _c218d328 = () => interopDefault(import('../pages/wechat-callback.vue' /* webpackChunkName: "pages/wechat-callback" */))
const _1c29f65a = () => interopDefault(import('../pages/organization/list.vue' /* webpackChunkName: "pages/organization/list" */))
const _3f97b515 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _8ef60474 = () => interopDefault(import('../pages/article-detail/_id/index.vue' /* webpackChunkName: "pages/article-detail/_id/index" */))
const _1e3021fc = () => interopDefault(import('../pages/article-edit/_id.vue' /* webpackChunkName: "pages/article-edit/_id" */))
const _d54f5014 = () => interopDefault(import('../pages/column-detail/_id/index.vue' /* webpackChunkName: "pages/column-detail/_id/index" */))
const _1b4ea7a8 = () => interopDefault(import('../pages/column-edit/_id.vue' /* webpackChunkName: "pages/column-edit/_id" */))
const _d25dafb4 = () => interopDefault(import('../pages/conference-edit/_id.vue' /* webpackChunkName: "pages/conference-edit/_id" */))
const _3502eda1 = () => interopDefault(import('../pages/meeting-detail/_id/index.vue' /* webpackChunkName: "pages/meeting-detail/_id/index" */))
const _fc5c5ea4 = () => interopDefault(import('../pages/organization/_id.vue' /* webpackChunkName: "pages/organization/_id" */))
const _86ce7846 = () => interopDefault(import('../pages/union/_id/index.vue' /* webpackChunkName: "pages/union/_id/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6da6a86d,
    name: "about"
  }, {
    path: "/edit-info",
    component: _4cbfe51e,
    children: [{
      path: "",
      component: _400efb34,
      name: "edit-info"
    }, {
      path: "apply-doctor",
      component: _df1f7828,
      name: "edit-info-apply-doctor"
    }, {
      path: "apply-info",
      component: _2db0213b,
      name: "edit-info-apply-info"
    }, {
      path: "change-phone",
      component: _4241331f,
      name: "edit-info-change-phone"
    }, {
      path: "change-pwd",
      component: _ba2048a4,
      name: "edit-info-change-pwd"
    }]
  }, {
    path: "/find",
    component: _5562ebee,
    name: "find",
    children: [{
      path: "column",
      component: _032c5868,
      name: "find-column"
    }, {
      path: "library",
      component: _42499696,
      name: "find-library"
    }, {
      path: "top-person",
      component: _1e9bd8c3,
      name: "find-top-person"
    }, {
      path: "top-person-quarter",
      component: _a50e07bc,
      name: "find-top-person-quarter"
    }, {
      path: ":id?/article",
      component: _38af0d9b,
      name: "find-id-article"
    }]
  }, {
    path: "/join",
    component: _ab855e0c,
    name: "join"
  }, {
    path: "/meeting",
    component: _1c100744,
    name: "meeting"
  }, {
    path: "/organization",
    component: _f6ed5674,
    name: "organization"
  }, {
    path: "/personal-center",
    component: _a4959e3c,
    name: "personal-center",
    children: [{
      path: "attention",
      component: _77ce7a9e,
      name: "personal-center-attention"
    }, {
      path: "column",
      component: _183b76d3,
      name: "personal-center-column"
    }, {
      path: "message",
      component: _20eb704c,
      name: "personal-center-message"
    }, {
      path: "meeting/:id?",
      component: _1c06eeb9,
      name: "personal-center-meeting-id"
    }, {
      path: "user-info/:id?",
      component: _367c03a4,
      name: "personal-center-user-info-id"
    }, {
      path: "weibo/:id?",
      component: _060da726,
      name: "personal-center-weibo-id"
    }, {
      path: ":id?",
      component: _0ece3d8d,
      name: "personal-center-id"
    }]
  }, {
    path: "/search-result",
    component: _4b0df745,
    name: "search-result"
  }, {
    path: "/user",
    component: _242fb1ca,
    name: "user",
    children: [{
      path: "forget",
      component: _7844a991,
      name: "user-forget"
    }, {
      path: "login",
      component: _64ab5075,
      name: "user-login"
    }, {
      path: "register",
      component: _8b4e0f72,
      name: "user-register"
    }]
  }, {
    path: "/vip",
    component: _7306a880,
    name: "vip"
  }, {
    path: "/wechat-callback",
    component: _c218d328,
    name: "wechat-callback"
  }, {
    path: "/organization/list",
    component: _1c29f65a,
    name: "organization-list"
  }, {
    path: "/agreement/:id?",
    component: _3f97b515,
    name: "agreement-id"
  }, {
    path: "/article-detail/:id?",
    component: _8ef60474,
    name: "article-detail-id"
  }, {
    path: "/article-edit/:id?",
    component: _1e3021fc,
    name: "article-edit-id"
  }, {
    path: "/column-detail/:id?",
    component: _d54f5014,
    name: "column-detail-id"
  }, {
    path: "/column-edit/:id?",
    component: _1b4ea7a8,
    name: "column-edit-id"
  }, {
    path: "/conference-edit/:id?",
    component: _d25dafb4,
    name: "conference-edit-id"
  }, {
    path: "/meeting-detail/:id",
    component: _3502eda1,
    name: "meeting-detail-id"
  }, {
    path: "/organization/:id",
    component: _fc5c5ea4,
    name: "organization-id"
  }, {
    path: "/union/:id?",
    component: _86ce7846,
    name: "union-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
