<template>
  <div :class="`header header__container ${fixedClass} header__${theme}`">
    <div class="header__box container">
      <!-- <div class="d-lg-none d-block mobile-menu">
        <div class="nav-menu" @click="toggleNav">
          <i v-if="!slideBarIsOpen" class="el-icon-s-unfold"></i>
          <i v-else class="el-icon-s-fold"></i>
        </div>
      </div> -->
      <nuxt-link to="/" class="header__board">
        <img
          v-if="configInfo.logo"
          :src="configInfo.logo"
          data-object-fit="contain"
          class="header__logo logo-white"
        />
      </nuxt-link>
      <div class="header__main">
        <ul class="header__nav nav nav__container">
          <li
            v-for="item in navList"
            :key="item.id"
            class="nav__item hvr-overline-from-center"
            :class="
              `${
                item.url === '/'
                  ? $route.path === item.url
                    ? 'active'
                    : ''
                  : $route.path.indexOf(item.url) > -1
                  ? 'active'
                  : ''
              }`
            "
          >
            <nuxt-link :to="item.url" class="nav__item__inner">{{
              item.text
            }}</nuxt-link>
            <div v-if="item.isSecond" class="nav__second">
              <ul class="nav__second-content">
                <li
                  v-for="sub in secondNavList"
                  :key="sub.id"
                  class="nav__second-item"
                >
                  <nuxt-link :to="sub.url">{{ sub.text }}</nuxt-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="header__action">
        <!-- <div v-show="!isLogedIn">
          <a href="javascript:;" @click="login">登录</a
          ><span class="split-line">/</span>
          <a href="javascript:;" @click="registry">注册</a>
        </div> -->
        <div class="header__search">
          <svg-icon icon-class="search" class="prefix"></svg-icon>
          <a-input
            v-model="searchWord"
            :disabled="false"
            class="header__search__input"
            @pressEnter="goSearch"
          />
          <span class="btn" @click="goSearch"
            >搜索 <a-icon type="swap-right"
          /></span>
        </div>
        <ul class="nav">
          <a-dropdown placement="bottomCenter" :trigger="['hover', 'click']">
            <li class="nav__item hvr-overline-from-center user__item">
              <a-avatar
                class="user-avatar"
                :size="34"
                :src="userInfo.avatarUrl || defaultUserIcon"
                icon="user"
                @click.native="goInfo"
              >
              </a-avatar>
            </li>
            <a-menu
              v-if="isLogedIn"
              slot="overlay"
              theme="dark"
              class="user-menu"
            >
              <a-menu-item>
                <nuxt-link to="/personal-center">个人主页</nuxt-link>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="logout">退出</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </ul>
      </div>
    </div>
    <div class="header__box-mobile">
      <nuxt-link to="/" class="header__board">
        <img
          v-if="configInfo.logo"
          :src="configInfo.logo"
          alt=""
          class="header__box-mobile-logo logo-white"
        />
        <img
          v-else-if="theme === 'dark'"
          src="~/assets/logo-white.png"
          alt=""
          class="header__box-mobile-logo logo-white"
        />
        <img
          v-else
          src="~/assets/logo-white.png"
          alt=""
          class="header__box-mobile-logo logo-white"
        />
      </nuxt-link>
      <div class="header__box-mobile-action">
        <a v-if="!isSearch" href="javascript:;" class="icon" @click="goSearch">
          <svg-icon icon-class="search"></svg-icon>
        </a>
        <a v-else href="javascript:;" class="icon" @click="closeSearch">
          <svg-icon icon-class="close"></svg-icon>
        </a>
        <a v-if="!mobileNav" href="javascript:;" class="icon">
          <svg-icon
            icon-class="fenlei"
            @click.native="showNavDrawer"
          ></svg-icon>
        </a>
        <a v-else href="javascript:;" class="icon">
          <svg-icon icon-class="close" @click.native="onClose"></svg-icon>
        </a>
        <a-avatar
          v-if="isLogedIn"
          class="user-avatar"
          :size="23"
          :src="userInfo.avatarUrl || defaultUserIcon"
          icon="user"
          @click.native="goInfo"
        >
        </a-avatar>
        <span
          v-if="isLogedIn"
          class="header__box-mobile-btn"
          style="margin-left:5px;"
        >
          <span @click="logout">退出</span>
        </span>

        <div v-else class="header__box-mobile-btn">
          <span @click="login">登录</span>/<span @click="registry">注册</span>
        </div>
      </div>
    </div>
    <a-drawer
      placement="left"
      :closable="false"
      :visible="mobileNav"
      wrap-class-name="slider-nav"
      @close="onClose"
    >
      <a-menu
        v-model="selectedKeys"
        :open-keys.sync="openKeys"
        mode="inline"
        @click="handleClick"
      >
        <template v-for="item in navList">
          <a-sub-menu v-if="item.isSecond" :key="item.url">
            <div slot="title" class="slider-nav-submenu-item">
              <span>{{ item.text }}</span>
              <svg-icon icon-class="arrow"></svg-icon>
            </div>
            <template v-if="item.isSecond">
              <a-menu-item v-for="sub in secondNavList" :key="sub.url">
                <div class="slider-nav-menu-item">
                  <span>{{ sub.text }}</span>
                  <svg-icon icon-class="arrow"></svg-icon>
                </div>
              </a-menu-item>
            </template>
          </a-sub-menu>
          <a-menu-item v-else :key="item.url">{{ item.text }}</a-menu-item>
        </template>
      </a-menu>
    </a-drawer>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapMutations, mapActions } from 'vuex'
import { getToken } from '@/services/token'
import userIconMan from '@/assets/user-man.png'
import userIconWoman from '@/assets/user-woman.png'
import defaultUser from '@/assets/user-icon.svg'

export default {
  name: 'TheHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'dark', // light
    },
  },
  data() {
    return {
      logo: '',
      searchWord: '',
      siteInfo: {},
      isHideHeader: false,
      currentScrollTop: 0,
      navList: [
        {
          id: 1,
          url: '/find/library/article',
          text: '发现',
          isSecond: true,
        },
        {
          id: 2,
          url: '/union',
          text: '联盟圈',
        },
        {
          id: 3,
          url: '/meeting',
          text: '会议',
        },
      ],
      secondNavList: [
        {
          id: 1,
          url: '/find/library/article',
          text: 'TOP 推荐',
        },
        {
          id: 2,
          url: '/find/news/article',
          text: '资讯',
        },
        {
          id: 3,
          url: '/find/library',
          text: '文库',
        },
        {
          id: 4,
          url: '/find/column',
          text: '专栏',
        },
        {
          id: 5,
          url: '/find/top-person',
          text: '名人榜',
        },
      ],
      mobileNav: false,
      openKeys: ['/find/library/article'],
      selectedKeys: [],
    }
  },

  computed: {
    ...mapState({
      isLogedIn: state => state.user.isLogedIn,
      userInfo: state => state.user.userInfo,
      slideBarIsOpen: state => state.setting.slideBarIsOpen,
      isSearch: state => state.setting.isSearch,
      configInfo: state => state['site-config'].configInfo,
    }),
    fixedClass() {
      return this.fixed ? 'is-fixed' : ''
    },
    defaultUserIcon() {
      if (!this.userInfo) {
        return defaultUser
      }
      return this.userInfo.gender === 'MALE'
        ? userIconMan
        : this.userInfo.gender === 'FEMALE'
        ? userIconWoman
        : defaultUser
    },
  },
  watch: {
    $route(to, from) {
      const isLogedIn = !!getToken()
      this.selectedKeys = [to.path]

      if (isLogedIn) {
        this.$store.dispatch('user/refreshUserInfo').catch(err => {
          this.$message.error(err.message)
        })

        this.getAuthentications().catch(err => {
          this.$message.error(err.message)
        })
      }
    },
  },
  created() {
    this.selectedKeys = [this.$route.path]
  },
  mounted() {
    // if (!wechatService.isMobile()) {
    //   this.handleScroll()
    // }

    const isLogedIn = !!getToken()
    if (isLogedIn) {
      this.mountUserInfo()
      this.$store.dispatch('user/refreshUserInfo').catch(err => {
        this.$message.error(err.message)
      })

      this.getAuthentications().catch(err => {
        this.$message.error(err.message)
      })
    }

    this.getAppConfigInfo().catch(err => {
      this.$message.error(err.message)
    })

    this.$message.config({
      top: `100px`,
    })
    this.getLibraryCategoryList().catch(err => {
      this.$message.error(err.message)
    })
    this.getSpecColumnList().catch(err => {
      this.$message.error(err.message)
    })

    this.getAllFieldConfigList().catch(err => {
      this.$message.error(err.message)
    })
  },

  methods: {
    ...mapMutations('setting', ['updateSlideBarStatus', 'updateSearchStatus']),
    ...mapMutations('user', ['mountUserInfo']),
    ...mapActions('user', ['getAuthentications']),
    ...mapActions('articles', ['getLibraryCategoryList', 'getSpecColumnList']),
    ...mapActions('site-config', ['getAppConfigInfo', 'getAllFieldConfigList']),
    login() {
      this.$router.push({ path: '/user/login' })
    },
    registry() {
      this.$router.push({ path: '/user/register' })
    },
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$message.success('退出成功')
        this.$router.push('/user/login')
      })
    },
    toggleNav() {
      this.updateSlideBarStatus(!this.slideBarIsOpen)
    },
    goInfo() {
      if (this.isLogedIn) {
        this.$router.push('/personal-center')
      } else {
        this.$router.push('/user/login')
      }
    },
    handleScroll(event) {
      document.body.addEventListener(
        'scroll',
        _.throttle(() => {
          const scrollTop = document.body.scrollTop
          if (Math.abs(this.currentScrollTop - scrollTop) < 30) {
            return
          }

          if (this.currentScrollTop < scrollTop) {
            this.isHideHeader = true
          } else {
            this.isHideHeader = false
          }
          this.currentScrollTop = scrollTop
        }, 500)
      )
    },
    goSearch() {
      this.onClose()
      this.updateSearchStatus(true)
      if (this.searchWord) {
        this.$router.push(`/search-result?keyword=${this.searchWord}`)
      } else {
        this.$router.push('/search-result')
      }

      this.searchWord = ''
    },
    closeSearch() {
      this.updateSearchStatus(false)
      this.$router.back()
    },
    showNavDrawer() {
      this.updateSearchStatus(false)
      this.mobileNav = true
    },
    onClose() {
      this.mobileNav = false
    },
    handleClick(e) {
      this.onClose()
      this.$router.push(e.key)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
@import '~/styles/hairline.scss';

.nav-menu {
  font-size: 16px;
  color: #fff;
  width: 40px;
}

.header {
  position: relative;
  z-index: 2;
  height: 66px;
  background-color: #545454;
  color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);

  &__board {
    position: absolute;
    left: 0;
    top: 2px;
  }

  &__light {
    background-color: #fff;
    color: #545454;
    .header {
      &__search {
        input {
          box-shadow: 0 0 10px 0 rgba(17, 38, 166, 0.2);
        }
        .btn {
          color: #fff;
        }
      }
      &__box {
        background-color: #fff;
      }
    }
    .nav {
      &__btn {
        margin-left: 20px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.2);
      }
      &__second {
        box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.08);
        background-color: #fff;
        color: #545454;
        a {
          color: #545454;
        }
      }
    }
    // .header__box-mobile {
    //   &-action {
    //     .icon {
    //       ::v-deep .svg-icon {
    //         color: #545454;
    //       }
    //       color: #545454;
    //     }
    //   }
    // }
  }
  &.is-fixed {
    position: fixed !important;
    z-index: 1500;
    left: 0;
    top: 0;
    right: 0;
  }
  &__board {
    a {
      display: block;
    }
    width: 165px;
    height: 62px;
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #545454;
    position: relative;
  }

  &__logo {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    @include object-fit(contain);
  }

  &__action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    a {
      color: #fff;
      cursor: pointer;
      font-size: 16px;
    }

    .split-line {
      font-size: 16px;
      color: inherit;
      margin: 0 3px;
    }
  }

  &.header-show {
    transform: translateY(0%);
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &.header-hide {
    transform: translateY(-100%);
    transition: all 300ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  // .user-avatar {
  //   // margin: 16px 0;
  // }
  .user__item {
    // width: 84px;
    padding: 0 25px;
    height: 66px;
    text-align: center;
    line-height: 66px;
    &:hover {
      font-weight: normal;
    }
  }

  &__search {
    position: relative;
    // margin-right: 24px;

    input {
      box-sizing: border-box;
      width: 150px;
      height: 32px;
      border-radius: 16px;
      background-color: #fff;
      border: none;
      transition: width 0.5s;
      cursor: pointer;
      font-size: 12px;
      padding: 7px 11px 7px 30px;
    }

    &__input {
      &:focus,
      &:active {
        width: 220px;
      }
    }

    .prefix {
      position: absolute;
      left: 10px;
      top: 9px;
      z-index: 10;
      display: inline-block;
      vertical-align: middle;
      transform: perspective(1px) translateZ(0);
      transform-origin: right bottom;
    }
    .btn {
      position: absolute;
      right: 3px;
      top: 2px;
      cursor: pointer;
      width: 66px;
      height: 28px;
      line-height: 28px;
      border-radius: 21px;
      box-shadow: 0 0 8px 0 rgba(211, 64, 28, 0.2);
      background-color: #d3401c;
      font-size: 12px;
      text-align: center;
    }

    &:hover {
      .prefix {
        animation-name: hvr-buzz-out;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-iteration-count: 2;
      }
    }
  }
}

.nav {
  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &__item {
    font-size: 16px;
    color: inherit;
    &__inner {
      display: block;
      color: inherit;
      padding: 21px 22px;
      cursor: pointer;
    }

    &.active {
      font-weight: bold;
    }

    &:hover {
      font-weight: bold;
      .nav__second {
        height: 50px;
      }
    }
  }

  &__btn {
    margin-left: 20px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &__second {
    display: flex;
    position: fixed;
    z-index: -10;
    left: 0;
    right: 0;
    top: 66px;
    height: 0;
    line-height: 50px;
    box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.08);
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    color: #fff;
    // opacity: 0;
    // display: none;
    // animation: slideDown 0.3s;
    // opacity: 0;
    transition: height cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    overflow: hidden;
    // transition: opacity ease-in 0.5s;
    overflow: hidden;
    &-content {
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: normal;
    }
    &-item {
      cursor: pointer;
      padding: 0 40px;
      transition: transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
      &:hover {
        font-weight: 600;
        transform: translateY(-4px);
      }
    }
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #c1c1c1;

  img {
    display: block;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    border-radius: 50%;
  }
}

.logout {
  margin-left: 10px;
}

.user-menu {
  border-radius: 0;
  background-color: #545454;
  top: -4px;
  text-align: center;
  a {
    color: #fff;
    font-weight: normal;
    font-size: 12px;
  }
  ::v-deep {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding: 5px 18px;
    }
  }
}
.header__box-mobile {
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 15px;
  &-logo {
    width: 115px;
    height: 34px;
    vertical-align: middle;
    @include object-fit(contain);
  }
  &-action {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 5px;
      color: #fff;
      padding: 10px;
      ::v-deep {
        .svg-icon {
          color: #fff;
        }
      }
    }
  }
  &-btn {
    display: inline-block;
    padding: 0 10px;
    box-shadow: 0 0 10px 0 rgba(17, 38, 166, 0.2);
    // width: 74px;
    height: 23px;
    line-height: 23px;
    background: #fff;
    color: #545454;
    font-size: 12px;
    border-radius: 12px;
    text-align: center;
  }

  .header__board {
    width: auto;
    height: auto;
  }
}
@media (max-width: 768px) {
  .header {
    height: 44px;
    &__board {
      position: static;
    }
  }
  .header__box-mobile {
    display: flex;
  }
  .header__box {
    display: none;
  }
}

.nuxt-link-exact-active.nuxt-link-active {
  color: #fff;
  // font-weight: bold;
}
</style>
<style lang="scss">
@import '~/styles/variables.scss';
.slider-nav {
  height: calc(100% - 44px);
  top: 44px;
  .ant-drawer-body {
    padding: 24px 0;
  }
  .ant-drawer-content {
    opacity: 0.9;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: $--color-primary;
    color: #fff;
    .svg-icon {
      color: #fff;
    }
  }
  .ant-menu-submenu-title {
    padding-right: 0;
  }
  .ant-menu-submenu {
    .ant-menu-submenu-arrow {
      display: none;
    }
    &.ant-menu-submenu-open {
      .slider-nav-submenu-item {
        .svg-icon {
          transform: rotate(-90deg);
        }
      }
    }
    & > .ant-menu {
      background-color: #f6f6f6;
    }
  }
  .ant-menu-item {
    color: #545454;
    font-size: 13px;
  }
  &-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    .svg-icon {
      color: #545454;
    }
  }
  &-submenu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    .svg-icon {
      color: #333;
    }
  }
}
</style>
