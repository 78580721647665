import _ from 'lodash'
import request from './request'

const isAndroid = () => {
  return window.navigator.userAgent
    .toLowerCase()
    .includes('Android'.toLowerCase())
}

const isIphone = () => {
  return window.navigator.userAgent
    .toLowerCase()
    .includes('iPhone'.toLowerCase())
}

const initSDK = () => {
  // let url = window.location.origin + window.location.pathname
  const url = window.location.href.split('#')[0]
  // const query = _.pick(querystring.parse(window.location.search.substr(1)), [
  //   'kind',
  // ])
  // if (query && !_.isEmpty(query)) {
  //   url = url + '?' + querystring.stringify(query)
  // }

  return request
    .get(`/share/config?url=${encodeURIComponent(url)}`)
    .then(response => {
      wx.config({
        appId: response.appId,
        timestamp: response.timestamp,
        nonceStr: response.nonceStr,
        signature: response.signature,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
        debug: false,
      })

      return new Promise((resolve, reject) => {
        wx.ready(() => {
          resolve()
        })

        wx.error(reject)
      })
    })
}

export default {
  initSDK,
  isWeiXin() {
    if (process.client) {
      return window.navigator.userAgent
        .toLowerCase()
        .includes('MicroMessenger'.toLowerCase())
    } else {
      return false
    }
  },
  isMobile() {
    if (process.client) {
      return isAndroid() || isIphone()
    }

    return false
  },
  share(options) {
    if (!this.isWeiXin()) {
      return Promise.resolve()
    }

    return initSDK().then(() => {
      return new Promise((resolve, reject) => {
        const result = {
          app: 0,
          timeline: 0,
        }
        const s = () => {
          if (result.app === 0 || result.timeline === 0) {
            return
          }

          if (result.app === 1 && result.timeline === 1) {
            resolve(result)
          } else {
            reject(result)
          }
        }

        const f = () => {
          if (result.app === 0 || result.timeline === 0) {
            return
          }

          reject(result)
        }

        // let url = window.location.origin + window.location.pathname
        // const query = _.pick(
        //   querystring.parse(window.location.search.substr(1)),
        //   ['kind']
        // )
        // if (query && !_.isEmpty(query)) {
        //   url = url + '?' + querystring.stringify(query)
        // }

        wx.updateAppMessageShareData({
          title: options.title,
          desc: options.description,
          link: options.link,
          imgUrl: !options.thumbnail
            ? window.location.origin + '/sandu-logo.png'
            : _.startsWith(options.thumbnail, 'http')
            ? options.thumbnail
            : `${window.location.protocol}${options.thumbnail}`,
          success() {
            result.app = 1
            s()
          },
          fail() {
            result.app = -1

            f()
          },
        })

        wx.updateTimelineShareData({
          title: options.title,
          desc: options.description,
          link: options.link,
          imgUrl: !options.thumbnail
            ? window.location.origin + '/sandu-logo.png'
            : _.startsWith(options.thumbnail, 'http')
            ? options.thumbnail
            : `${window.location.protocol}${options.thumbnail}`,
          success() {
            result.timeline = 1

            s()
          },
          fail() {
            result.timeline = -1

            f()
          },
        })
      })
    })
  },
}
