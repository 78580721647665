import request from './request'

export default {
  fetchConfigurations() {
    return request.get('/site-config')
  },
  fetchJoinUsList(params) {
    return request.get('/jobs', { params })
  },
  updateConfigurations(id, payload) {
    // return request.put(`/site-config/${id}`, payload)
  },
  fetchFieldConfig(category) {
    return request.get(`/field-config/${category}/fields`)
  },
  fetchVipRight(params) {
    return request.get(`/vip-right`, { params })
  },
  fetchOrganizations(params) {
    return request.get(`/organizations`, { params })
  },
  fetchOrganizationsDetail(id) {
    return request.get(`/organizations/${id}`)
  },
}
