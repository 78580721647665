import _ from 'lodash'
import * as filters from './filters' // global filters

export default {
  install(Vue) {
    _.each(filters, (value, key) => {
      Vue.filter(key, value)
    })
  },
}
