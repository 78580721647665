import request from './request'

const keyName = 'sandu-web'

export default {
  getLoginURL() {
    return request.post('/auth/login/web').then(response => {
      return response.loginURL
    })
  },
  login(payload) {
    return request.post('/auth/login', payload)
  },
  setRedireectURL(url) {
    window.localStorage.setItem(keyName, url)
  },
  getRedirectURL() {
    const result = window.localStorage.getItem(keyName)

    return result || '/profile/info'
  },
  sendCode(params) {
    return request.get('/auth/code', { params })
  },
  signup(payload) {
    return request.post('/auth/signup', payload)
  },
  updatePassword(payload) {
    return request.put('/auth/update-password', payload)
  },
  resetPassword(payload) {
    return request.post('/auth/reset-password', payload)
  },
  changeUserPhone(payload) {
    return request.put('/user/info/phone', payload)
  },
  fetchAuthentications(params) {
    return request.get('/latest-authentications', { params })
  },
  updateAuthentications(payload) {
    return request.post('/authentications', payload)
  },
  checkPhoneIsUsed(payload) {
    return request.post('/auth/phone', payload)
  },
}
