export function getPosition(node) {
  if (!node) return { left: null, top: null }
  let left = node.offsetLeft // 获取元素相对于其父元素的left值var left
  let top = node.offsetTop
  let current = node.offsetParent // 取得元素的offsetParent
  // 一直循环直到根元素
  while (current != null) {
    left += current.offsetLeft
    top += current.offsetTop
    current = current.offsetParent
  }
  return {
    left,
    top,
  }
}

export function isIE() {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}
