<template>
  <div :class="`app-panel`">
    <app-title :title="title" @click="handleMoreClick"></app-title>
    <div class="app-panel__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPanel',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleMoreClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-panel {
  &__body {
  }
}

@media (max-width: 768px) {
  .app-panel {
    &__body {
    }
  }
}
</style>
