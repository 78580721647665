<template>
  <div class="app-list-item">
    <div class="app-list-item-meta">
      <div class="app-list-item-meta-avatar">
        <span
          v-if="tag"
          class="app-list-item-tag"
          @click.stop="handleTagClick(tagText)"
          >{{ tagText }}</span
        >
        <slot name="avatar">
          <a-avatar
            :size="48"
            :src="avatar"
            icon="user"
            @click="$emit('avatarClick')"
          >
          </a-avatar>
        </slot>
      </div>
      <div class="app-list-item-meta-content">
        <slot></slot>
        <div class="app-list-item-nested">
          <slot name="nested"></slot>
        </div>
      </div>
    </div>
    <div class="app-list-item-action">
      <slot name="action">
        <div class="app-list-item-action-content">
          <a-icon type="swap-right" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppListItem',
  props: {
    avatar: {
      type: String,
      default: '',
    },
    tag: {
      type: Boolean,
      default: false,
    },
    tagText: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleTagClick(text) {
      this.$emit('tagClick', text)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.app-list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  // cursor: pointer;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
  &-tag {
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 1px 5px;
    min-width: 44px;
    max-width: 90%;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-meta {
    display: flex;
    flex: 1;
    // width: 0;
    align-items: center;
    &-avatar {
      flex: 0 0 auto;
      position: relative;
      margin-right: 30px;
      cursor: pointer;
    }
    &-content {
      flex: 1 1 auto;
      // width: 0;
    }
  }
  &-action {
    flex: 0 0 auto;
    margin-left: 15px;
    padding: 0;
    i {
      color: #808080;
      font-size: 18px;
    }
    &-content {
      font-size: 14px;
      width: 100px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border-left: 1px solid #ccc;
    }
  }

  &-nested {
    background-color: rgba(239, 241, 245, 0.7);

    & > div:first-child {
      margin-top: 20px;
    }

    .app-list-item {
      &-inner {
        border-top-color: transparent;
        padding: 40px 20px;
      }
      &-form {
        &-input {
          background-color: #fff;
        }
        ::v-deep {
          .ant-input {
            background-color: #fff;
          }
        }
      }
    }
    .app-popover-arrow {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .app-list-item {
    &-nested {
      margin-left: 0px;
      background-color: rgba(239, 241, 245, 0.7);
      .app-list-item {
        &-inner {
          border-top-color: transparent;
          padding: 16px 0 10px 9px;
        }
        &-form {
          &-input {
            background-color: #fff;
          }
          ::v-deep {
            .ant-input {
              background-color: #fff;
            }
          }
        }
      }
      .app-popover-arrow {
        display: none;
      }
    }
  }
}
</style>
