<template>
  <div class="passowrd-input">
    <a-input
      ref="password"
      v-model="password"
      :type="passwordType"
      :placeholder="placeholder"
      name="password"
      autocomplete="off"
      size="large"
      @keyup.enter.native="$emit('keyup-enter')"
    />
    <span class="show-pwd" @click="toggleEyes">
      <SvgIcon
        :icon-class="passwordType === 'password' ? 'Notvisible' : 'view'"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEyesOpen: false,
    }
  },
  computed: {
    password: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    passwordType() {
      return this.isEyesOpen ? 'text' : 'password'
    },
  },
  methods: {
    toggleEyes() {
      this.isEyesOpen = !this.isEyesOpen
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';

.passowrd-input {
  position: relative;
  display: inline-block;
  width: 100%;
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    ::v-deep {
      .svg-icon {
        color: $--color-primary;
      }
    }
  }
}
</style>
