<template>
  <div class="app-new-card">
    <div class="app-new-card-title">{{ title }}</div>
    <p class="app-new-card-desc">{{ content }}</p>
    <div class="app-new-card-time">{{ time }}</div>
    <div class="app-new-card-detail">
      <app-button type="primary"
        >查看详情<a-icon type="swap-right"
      /></app-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNewCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';

.app-new-card {
  padding: 38px 40px 30px;
  border: 1px solid transparent;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid #545454;
  }
  &-title {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    color: #000;
    margin-bottom: 20px;
    height: 44px;
    @include text-ellipsis(2);
  }
  &-desc {
    display: block;
    height: 64px;
    font-size: 12px;
    line-height: 1.82;
    color: #666;
    margin-bottom: 10px;
    @include text-ellipsis(2);
  }
  &-time {
    font-size: 12px;
    line-height: 2;
    color: #000;
    margin-bottom: 28px;
  }
  &-detail {
    text-align: center;
    ::v-deep {
      .app-button {
        padding: 0 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .app-new-card {
    padding: 15px 0;
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      border: 1px solid transparent;
      border-bottom: 1px solid #e5e5e5;
    }
    &-title {
      display: block;
      margin-bottom: 5px;
      height: 22px;
      @include ellipsis;
    }
    &-desc {
      margin-bottom: 15px;
      line-height: 1.4;
      height: auto;
    }
    &-time {
      font-size: 12px;
      color: #999;
      margin-bottom: 0;
    }
    &-detail {
      display: none;
    }
  }
}
</style>
