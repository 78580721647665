<template>
  <div class="app-empty">
    <a-empty :description="description" />
  </div>
</template>

<script>
export default {
  name: 'AppEmpty',
  props: {
    description: {
      type: String,
      default: '暂无数据',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
.app {
  &-empty {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
// @media screen and (max-width: 768px) {

// }
</style>
