export const state = () => ({
  slideBarIsOpen: false,
  isSearch: false,
})

export const mutations = {
  updateSlideBarStatus(state, payload) {
    state.slideBarIsOpen = payload
  },
  updateSearchStatus(state, payload) {
    state.isSearch = payload
  },
}
