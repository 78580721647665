<template>
  <div class="organization-card-item">
    <h3 class="organization-card-item-title">{{ title }}</h3>
    <div class="organization-card-item-address">{{ address }}</div>
    <p class="organization-card-item-content ck-content">{{ content }}</p>
  </div>
</template>

<script>
export default {
  name: 'AppOrganizationCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
.organization {
  &-card {
    &-item {
      margin-bottom: 48px;
      padding: 28px 32px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.03);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
      cursor: pointer;
      height: 150px;
      &:hover {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      }
      &-title {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        color: $--color-primary;
        margin-bottom: 14px;
      }
      &-address {
        font-size: 14px;
        color: #333;
        margin-bottom: 14px;
      }
      &-content {
        font-size: 14px;
        line-height: 1.5;
        color: #333;
        // height: 300px;
        white-space: pre-line;
        margin-bottom: 0;
        overflow: auto;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .organization {
    &-card {
      &-item {
        margin-bottom: 15px;
        padding: 14px 18px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
        height: 108px;
        &-title {
          font-size: 15px;
          margin-bottom: 10px;
        }
        &-address {
          margin-bottom: 10px;
          font-size: 13px;
        }
        &-content {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
