import axios from 'axios'
import request from './request'

export default {
  fetchUserInfo() {
    return request.get('/user/info')
  },
  fetchUserFlowers(params) {
    return request.get('/idols', { params })
  },
  fetchUserMessages(params) {
    return request.get('/messages', { params })
  },
  fetchUserUnReadMessages(params) {
    return request.get('/unread-messages', { params })
  },
  updateUserReadMessage(id) {
    return request.post(`/messages/${id}/read`)
  },
  fetchUserInfoByUserID(id) {
    return request.get(`/users/${id}`)
  },
  updateUserInfo(payload) {
    return request.put('/user/info', payload)
  },
  fetchUserCourses() {
    return request.get('/user/courses')
  },
  postInvitations(inviterID) {
    return request.post('/invitations', { inviterID })
  },
  getCitys() {
    return axios.get('/country_tree.json')
  },
  deleteUserFlower(id) {
    return request.post(`/users/${id}/unfollow`)
  },
  checkUserFlower(id) {
    return request.get(`/users/${id}/check`)
  },
  checkArticleUserStatus(kind, id) {
    return request.get(`/kinds/${kind}/articles/${id}/check`)
  },
  addUserFlower(id) {
    return request.post(`/users/${id}/follow`)
  },
  shareCountUpdate(path) {
    return request.post(path)
  },
}
