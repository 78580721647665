import Vue from 'vue'

import _ from 'lodash'
import normalizr from '@/utilities/normalizr'
import conferenceService from '@/services/conference'

export const state = () => ({
  idList: [],
  myIDList: [],
  myContributionIDList: [],
  dataSource: {},
  pagination: {
    page: 1,
    perPage: 10,
  },
  ownPagination: {
    page: 1,
    perPage: 10,
  },
  ownContributionPagination: {
    page: 1,
    perPage: 10,
  },
  count: 0,
  ownCount: 0,
  ownContributionCount: 0,
})

export const getters = {
  myConferenceList(state) {
    return _.map(state.myIDList, id => state.dataSource[id])
  },
  myContributionList(state) {
    return _.map(state.myContributionIDList, id => state.dataSource[id])
  },
  conferenceList(state) {
    return _.map(state.idList, id => state.dataSource[id])
  },
}

export const mutations = {
  setConferenceData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.idList =
      pagination.page === 1 ? idList : _.concat(state.idList, idList)
    state.pagination = pagination
    state.count = count

    _.assign(state.dataSource, normalizr(data))
  },
  setMyConferenceData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.myIDList =
      pagination.page === 1 ? idList : _.concat(state.myIDList, idList)
    state.ownPagination = pagination
    state.ownCount = count

    _.assign(state.dataSource, normalizr(data))
  },
  setMyContributionData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.myContributionIDList =
      pagination.page === 1
        ? idList
        : _.concat(state.myContributionIDList, idList)
    state.ownContributionPagination = pagination
    state.ownContributionCount = count

    _.assign(state.dataSource, normalizr(data))
  },
  updateMyConferenceInfo(state, payload) {
    if (!_.includes(state.myIDList, payload.id)) {
      state.myIDList.push(payload.id)
    }
    Vue.set(
      state.dataSource,
      payload.id,
      _.assign({}, state.dataSource[payload.id], payload)
    )
  },
  deleteMyConferenceID(state, id) {
    if (_.includes(state.myIDList, id)) {
      state.ownCount = state.ownCount - 1
      state.myIDList = _.without(state.myIDList, id)
    }
  },
  deleteMyContributionID(state, id) {
    if (_.includes(state.myContributionIDList, id)) {
      state.ownContributionCount = state.ownContributionCount - 1
      state.myContributionIDList = _.without(state.myContributionIDList, id)
    }
  },
  updateMyContributionInfo(state, payload) {
    if (!_.includes(state.myContributionIDList, payload.id)) {
      state.myContributionIDList.push(payload.id)
    }
    Vue.set(
      state.dataSource,
      payload.id,
      _.assign({}, state.dataSource[payload.id], payload)
    )
  },
  updateConferenceInfo(state, payload) {
    if (!_.includes(state.idList, payload.id)) {
      state.idList.push(payload.id)
    }
    Vue.set(
      state.dataSource,
      payload.id,
      _.assign({}, state.dataSource[payload.id], payload)
    )
  },
}

export const actions = {
  getConferenceList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 20,
    })

    return conferenceService.fetchConferenceList(payload).then(response => {
      context.commit('setConferenceData', response)
    })
  },
  getMyConferenceList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
    })

    return conferenceService.fetchUserConferenceList(payload).then(response => {
      context.commit('setMyConferenceData', response)
    })
  },
  getMyContributionList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
    })

    return conferenceService
      .fetchUserContributionList(payload)
      .then(response => {
        context.commit('setMyContributionData', response)
      })
  },
  getMyConferenceInfo(context, id) {
    return conferenceService.fetchUserConferenceInfo(id).then(response => {
      context.commit('updateMyConferenceInfo', response)
      return response
    })
  },
  getMyContributionInfo(context, id) {
    return conferenceService.fetchUserContributionInfo(id).then(response => {
      context.commit('updateMyContributionInfo', response)
    })
  },
  addMyConferenceInfo(context, payload) {
    return conferenceService.addConferenceInfo(payload).then(response => {
      context.commit('updateMyConferenceInfo', response)
    })
  },
  updateMyConferenceInfo(context, payload) {
    return conferenceService
      .updateConferenceInfo(payload.id, payload)
      .then(response => {
        context.commit('updateMyConferenceInfo', response)
      })
  },
  addMyContributionInfo(context, payload) {
    return conferenceService
      .addContributionInfo(payload.id, payload)
      .then(response => {
        context.commit('updateMyContributionInfo', response)
      })
  },
  deleteMyConferenceInfo(context, id) {
    return conferenceService.deleteUserConferenceInfo(id).then(response => {
      context.commit('deleteMyConferenceID', id)
    })
  },
  deleteMyContributionInfo(context, payload) {
    return conferenceService
      .deleteUserContributionInfo(payload.conferenceID, payload.contributionID)
      .then(response => {
        context.commit('deleteMyContributionID', response)
      })
  },
  getConferenceInfo(context, id) {
    return conferenceService.fetchConferenceInfo(id).then(response => {
      context.commit('updateConferenceInfo', response)
      return response
    })
  },
}
