import Vue from 'vue'
import { isIE } from '@/utilities'

const CkEditor = () => import('@/plugins/CkEditor')
const CkEditorIe = () => import('@/plugins/CkEditorIe')
// register component from plugin to bypass SSR
if (isIE()) {
  Vue.component('ck-editor', CkEditorIe)
} else {
  Vue.component('ck-editor', CkEditor)
}
// Vue.component('ck-editor', CkEditor)
