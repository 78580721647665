import Vue from 'vue'
import _ from 'lodash'

import MessageModal from '@/components/AppMessageModal'

const render = options => {
  const opts = _.defaults(options, {
    type: 'error',
    title: '',
    message: '',
    visible: true,
  })

  const Component = Vue.extend(MessageModal)

  const vm = new Component({
    data: opts,
  }).$mount()

  document.body.appendChild(vm.$el)
}

Vue.prototype.$throw = (title, message) => {
  render({
    type: 'error',
    title,
    message,
  })
}

Vue.prototype.$success = (title, message, type) => {
  render({
    type: type || 'success',
    title,
    message,
  })
}
