<template>
  <a-locale-provider :locale="locale">
    <div class="layout-container">
      <The-header fixed />
      <div class="layout-content">
        <nuxt />
      </div>
      <The-footer />
      <app-back-top></app-back-top>
    </div>
  </a-locale-provider>
</template>
<script>
import { mapActions } from 'vuex'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'Layout',
  data() {
    return {
      siteInfo: {},
      locale: zhCN,
    }
  },
  mounted() {
    this.getAppConfigInfo().catch(err => {
      this.$message.error(err.message)
    })
  },
  methods: {
    ...mapActions('site-config', ['getAppConfigInfo', 'getAllFieldConfigList']),
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
@import '~/styles/hairline.scss';
.layout-content {
  padding-top: 66px;
}

@media (max-width: 768px) {
  .layout-content {
    padding-top: 44px;
  }
}
</style>
