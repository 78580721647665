import Vue from 'vue'
import globalRegistration from '@/utilities/global-registration'
import mixins from '@/mixins'
import filters from '@/filters'
import InfiniteScroll from '@/directive/infinite-scroll'
globalRegistration(Vue)

Vue.use(mixins)
Vue.use(filters)
Vue.use(InfiniteScroll)
// console.log(
//   `%c${BUILD_INFO.name}%cv${BUILD_INFO.version}`,
//   'background: #35495e; color: #fff; padding: 2px 4px; border-radius: 3px 0 0 3px;',
//   'background: #19be6b; color: #fff; padding: 2px 4px; border-radius: 0 3px 3px 0;'
// )
