<template>
  <div>
    <textarea></textarea>
  </div>
</template>
<script>
import { getEditorNamespace } from '@/utilities/geteditornamespace.js'
export default {
  name: 'Ckeditor4Vue',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'classic',
      validator: type => ['classic', 'inline'].includes(type),
    },
    editorUrl: {
      type: String,
      default: 'https://cdn.ckeditor.com/4.14.0/standard-all/ckeditor.js',
    },
    config: {
      type: Object,
      default: () => {},
    },
    tagName: {
      type: String,
      default: 'textarea',
    },
    readOnly: {
      type: Boolean,
      default: null, // Use null as the default value, so `config.readOnly` can take precedence.
    },
  },

  watch: {
    value(val) {
      if (this.instance.getData() !== val) {
        this.instance.setData(val)
      }
    },

    readOnly(val) {
      this.instance.setReadOnly(val)
    },
  },
  mounted() {
    getEditorNamespace(this.editorUrl).then(() => {
      if (this.$_destroyed) {
        return
      }

      const config = this.config || {}

      if (this.readOnly !== null) {
        config.readOnly = this.readOnly
      }

      const method = this.type === 'inline' ? 'inline' : 'replace'
      const element = this.$el.firstElementChild
      const editor = (this.instance = window.CKEDITOR[method](element, config))

      editor.on('instanceReady', () => {
        const data = this.value

        editor.fire('lockSnapshot')

        editor.setData(data, {
          callback: () => {
            this.$_setUpEditorEvents()

            const newData = editor.getData()

            // Locking the snapshot prevents the 'change' event.
            // Trigger it manually to update the bound data.
            if (data !== newData) {
              this.$once('input', () => {
                this.$emit('ready', editor)
              })

              this.$emit('input', newData)
            } else {
              this.$emit('ready', editor)
            }

            editor.fire('unlockSnapshot')
          },
        })
      })
    })
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy()
    }

    this.$_destroyed = true
  },
  methods: {
    $_setUpEditorEvents() {
      const editor = this.instance

      editor.on('change', evt => {
        const data = editor.getData()

        // Editor#change event might be fired without an actual data change.
        if (this.value !== data) {
          // The compatibility with the v-model and general Vue.js concept of input–like components.
          this.$emit('input', data, evt, editor)
        }
      })

      editor.on('focus', evt => {
        this.$emit('focus', evt, editor)
      })

      editor.on('blur', evt => {
        this.$emit('blur', evt, editor)
      })
    },
  },
}
</script>
