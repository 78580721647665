import Vue from 'vue'

import _ from 'lodash'
import normalizr from '@/utilities/normalizr'
import articleService from '@/services/articles'

import { STARTYPE, VERIFYSTATUS } from '@/utilities/constants'

// const nameOfIdListWithType = type => {
//   let nameOfIdList = ''

//   switch (type) {
//     case ARTICLEKIND.NEWS:
//       nameOfIdList = 'newsIDList'
//       break
//     case ARTICLEKIND.LIBRARY:
//       nameOfIdList = 'libraryIDList'
//       break
//     case ARTICLEKIND.SPECIAL_COLUMN:
//       nameOfIdList = 'specIDList'
//       break
//     default:
//       throw new Error('Unsupported cache action type.')
//   }

//   return nameOfIdList
// }

export const state = () => ({
  idList: [],
  allIDList: [],
  favorIDList: [],
  draftIDList: [],
  newsIDList: [],
  specIDList: [],
  libraryIDList: [],
  libraryCategoryIDList: [],
  libraryCategoryDataSource: {},
  specColumnIDList: [],
  specColumnDataSource: {},
  dataSource: {},
  tagIDList: [],
  tagDataSource: {},
  pagination: {
    page: 1,
    perPage: 10,
  },
  count: 0,
  allPagination: {
    page: 1,
    perPage: 10,
  },
  allCount: 0,
  draftPagination: {
    page: 1,
    perPage: 10,
  },
  draftCount: 0,
  favorPagination: {
    page: 1,
    perPage: 10,
  },
  favorCount: 0,
})

export const getters = {
  myArticleList(state) {
    return _.map(state.idList, id => state.dataSource[id])
  },
  allArticleList(state) {
    return _.map(state.allIDList, id => state.dataSource[id])
  },
  myFavorArticleList(state) {
    return _.map(state.favorIDList, id => state.dataSource[id])
  },
  myDraftArticleList(state) {
    return _.map(state.draftIDList, id => state.dataSource[id])
  },
  tagList(state) {
    return _.map(state.tagIDList, id => state.tagDataSource[id])
  },
  libraryCategoryList(state) {
    return _.map(
      state.libraryCategoryIDList,
      id => state.libraryCategoryDataSource[id]
    )
  },
  specColumnList(state) {
    return _.map(state.specColumnIDList, id => state.specColumnDataSource[id])
  },
}

export const mutations = {
  setTagData(state, { data }) {
    const idList = _.map(data, 'id')

    state.tagIDList = idList

    _.assign(state.tagDataSource, normalizr(data))
  },
  setLibraryCategoryData(state, data) {
    const idList = _.map(data, 'id')

    state.libraryCategoryIDList = idList

    _.assign(state.libraryCategoryDataSource, normalizr(data))
  },
  setSpecColumnData(state, data) {
    const idList = _.map(data, 'id')

    state.specColumnIDList = idList

    _.assign(state.specColumnDataSource, normalizr(data))
  },
  setArticleData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.idList =
      pagination.page === 1 ? idList : _.concat(state.idList, idList)
    state.pagination = pagination
    state.count = count

    _.assign(state.dataSource, normalizr(data))
  },
  setAllArticleData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.allIDList =
      pagination.page === 1 ? idList : _.concat(state.allIDList, idList)
    state.allPagination = pagination
    state.allCount = count

    _.assign(state.dataSource, normalizr(data))
  },
  setFavorArticleData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.favorIDList =
      pagination.page === 1 ? idList : _.concat(state.favorIDList, idList)
    state.favorPagination = pagination
    state.favorCount = count

    _.assign(state.dataSource, normalizr(data))
  },
  setDraftArticleData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.draftIDList =
      pagination.page === 1 ? idList : _.concat(state.draftIDList, idList)
    state.draftPagination = pagination
    state.draftCount = count

    _.assign(state.dataSource, normalizr(data))
  },
  updateArticleData(state, payload) {
    Vue.set(
      state.dataSource,
      payload.id,
      _.assign({}, state.dataSource[payload.id], payload)
    )
  },
  addArticleInfo(state, payload) {
    if (!_.includes(state.idList, payload.id)) {
      state.idList.push(payload.id)
    }
    Vue.set(
      state.dataSource,
      payload.id,
      _.assign({}, state.dataSource[payload.id], payload)
    )
  },
  deleteArticleData(state, id) {
    if (!id) {
      return
    }
    state.count = state.count - 1
    state.idList = _.without(state.idList, id)
  },
  deleteDraftArticleData(state, id) {
    if (!id) {
      return
    }
    state.draftCount = state.draftCount - 1
    state.draftIDList = _.without(state.draftIDList, id)
  },
  updateArticleFavorIDList(state, payload) {
    if (payload) {
      if (payload.action === 'UP') {
        state.favorIDList = _.uniq(_.concat(state.favorIDList, payload.id))
      } else {
        state.favorIDList = _.without(state.favorIDList, payload.id)
      }
    }
  },
  cancelArticleStatus(state, id) {
    if (!id) {
      return
    }

    _.assign(state.dataSource[id], { status: VERIFYSTATUS.EDITING })
  },
}

export const actions = {
  getTagList(context, payload) {
    return articleService.fetchTagList(payload).then(response => {
      context.commit('setTagData', response)
    })
  },
  getLibraryCategoryList(context, payload) {
    return articleService.fetchLibraryCategoryList(payload).then(response => {
      context.commit('setLibraryCategoryData', response)
    })
  },
  getSpecColumnList(context, payload) {
    return articleService.fetchSpecArticleList(payload).then(response => {
      context.commit('setSpecColumnData', response)
    })
  },
  getMyArticleList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
    })

    return articleService.fetchMyArticleList(payload).then(response => {
      context.commit('setArticleData', response)
    })
  },
  cancelMyArticleStatus(context, payload) {
    if (!payload || _.isEmpty(payload)) {
      return
    }

    return articleService
      .cancelArticleStatus(payload.kind, payload.id)
      .then(() => {
        context.commit('cancelArticleStatus', payload.id)
      })
  },
  getAllArticleList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
    })

    return articleService.fetchArticleList('ALL', payload).then(response => {
      context.commit('setAllArticleData', response)
    })
  },
  getMyFavorArticleList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
      targetType: STARTYPE.ARTICLE,
    })

    return articleService.fetchMyFavorArticleList(payload).then(response => {
      context.commit('setFavorArticleData', response)
    })
  },
  getMyDraftArticleList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
      isDraft: true,
    })

    return articleService.fetchMyArticleList(payload).then(response => {
      context.commit('setDraftArticleData', response)
    })
  },
  getArticleInfo(context, payload) {
    return articleService
      .fetchArticleInfo(payload.kind, payload.id)
      .then(response => {
        context.commit('updateArticleData', response)
        return response
      })
  },
  addArticleInfo(context, payload) {
    return articleService
      .addArticleInfo(payload.kind, payload)
      .then(response => {
        context.commit('addArticleInfo', response)
      })
  },
  updateArticleInfo(context, payload) {
    return articleService
      .updateArticleInfo(payload.kind, payload.id, payload)
      .then(response => {
        context.commit('updateArticleData', response)
      })
  },
  deleteArticleInfo(context, payload) {
    return articleService
      .deleteArticleInfo(payload.kind, payload.id)
      .then(() => {
        context.commit('deleteArticleData', payload.id)
      })
  },
  deleteDraftArticleInfo(context, payload) {
    return articleService
      .deleteArticleInfo(payload.kind, payload.id)
      .then(() => {
        context.commit('deleteDraftArticleData', payload.id)
      })
  },
  updateArticleStarStatus(context, payload) {
    return articleService
      .updateArticleStarStatus(payload.articleID, payload)
      .then(() => {
        context.commit('updateArticleFavorIDList', payload)
      })
  },
}
