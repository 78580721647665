<template>
  <div :class="`app-open-course app-open-course-${size}`" :style="{ width }">
    <div class="app-open-course-thumbnail">
      <img
        v-lazy="thumbnail"
        alt=""
        class="app-open-course-img image-radius"
        data-object-fit="cover"
      />
      <svg-icon
        v-if="hasVideo"
        class="app-open-course-start"
        icon-class="kaishi"
      ></svg-icon>
    </div>
    <div class="app-open-course-body">
      <div class="app-open-course-time">{{ time }}</div>
      <h3 class="app-open-course-title">{{ title }}</h3>
      <p class="app-open-course-desc">{{ description }}</p>
      <app-button
        class="app-open-course-btn"
        type="primary"
        dashed
        @click="goDetail"
        >进入课程</app-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppOpenCourse',
  props: {
    thumbnail: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '235px',
    },
    size: {
      type: String,
      default: 'normal',
    },
    hasVideo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goDetail() {
      this.$emit('entry')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
@import '~/styles/hairline.scss';

.app-open-course {
  position: relative;
  display: inline-block;
  margin: 0 10px 30px;
  width: 235px;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
  &.app-open-course-large {
    width: 100% !important;
    display: flex;
    .app-open-course {
      &-thumbnail {
        width: 500px;
        height: 300px;
        flex: 0 0 auto;
      }
      &-start {
        position: absolute;
        left: 40px;
        bottom: 40px;
        font-size: 64px;
      }
      &-body {
        padding: 40px 50px 0 50px;
        flex: 1 1 auto;
      }
      &-title {
        font-size: 18px;
        margin-bottom: 20px;
        height: 52px;
      }
      &-desc {
        display: block;
        margin-bottom: 50px;
        height: 32px;
        @include text-ellipsis(2);
      }
    }
  }
  &-thumbnail {
    position: relative;
    height: 150px;
  }
  &-start {
    position: absolute;
    left: 16px;
    bottom: 16px;
    font-size: 28px;
  }
  &-img {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }

  &-body {
    padding: 10px 16px 18px;
  }
  &-time {
    font-size: 12px;
    color: #000;
    margin-bottom: 14px;
  }
  &-title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 1.3;
    margin-bottom: 12px;
    height: 40px;
    @include line(2);
  }
  &-desc {
    font-size: 12px;
    color: #999;
    @include line(2);
    line-height: 1.4;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .app-open-course {
    display: flex;
    margin-bottom: 20px;
    margin-right: 0;
    width: 100% !important;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
    &.app-open-course-large {
      display: none;
    }
    &-thumbnail {
      width: 105px;
      height: 70px;
    }
    &-start {
      left: 5px;
      bottom: 5px;
      font-size: 14px;
    }

    &-body {
      flex: 1;
      padding: 0 10px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }
    &-time {
      font-size: 12px;
      color: #666;
      margin-bottom: 0;
    }
    &-title {
      font-size: 14px;
      font-weight: normal;
      color: #333;
      margin-bottom: 8px;
      line-height: 1.3;
      height: auto;
      @include line(2);
    }
    &-desc {
      display: none;
    }
    &-btn {
      display: none;
    }
  }
}
</style>
