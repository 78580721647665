<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :closable="false"
    :keyboard="false"
    :mask-closable="false"
    :width="width"
    class="app-modal-form"
    :class="customClass"
    :centered="true"
  >
    <div class="model-form__container">
      <div class="model-form__body">
        <h4 v-show="title" class="model-form__title">
          {{ title }}
        </h4>
        <div class="model-form__content">
          <slot></slot>
        </div>
      </div>
      <div class="model-form__footer">
        <div class="model-form__footer__confirm">
          <slot name="cancel">
            <span class="btn cancel" @click.stop="onCancel">取消</span>
          </slot>
          <a-divider class="line" type="vertical" />
          <slot name="ok">
            <span class="btn ok" @click.stop="onOk">确认</span>
          </slot>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalForm',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 240,
    },
  },
  data() {
    return {
      visible: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.visible = newVal
    },
    visible(newVal) {
      this.$emit('input', newVal)
    },
  },
  methods: {
    onCancel() {
      // this.visible = false
      this.$emit('cancel')
    },
    onOk() {
      this.$emit('ok')
    },
  },
}
</script>

<style lang="scss">
.model-form {
  &__container {
    // text-align: center;
  }
  &__title {
    font-size: 16px;
    text-align: left;
    color: #000;
    font-weight: 600;
  }
  &__content {
    // max-height: 60vh;
    // overflow: auto;
  }
  &__footer {
    padding: 50px 0 7px;
    font-size: 12px;
    &__confirm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // .btn {
      //   padding: 0 24px;
      // }
      .cancel {
        color: #999;
      }
      .ok {
        color: #5368e7;
      }
      .line {
        color: #c6c6c6;
        height: 16px;
      }
    }
    &__action {
      text-align: center;
    }
  }
}
.app-modal-form {
  .ant-modal-body {
    padding: 15px;
  }
  .ant-modal-content {
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  .app-modal-form {
    width: 100% !important;
    padding: 0 30px;
    margin-top: 50px;
  }
}
</style>
