import request from './request'

export default {
  fetchConferenceList(params) {
    return request.get('/conferences', { params })
  },
  fetchMyStarConferenceList(params) {
    return request.get('/user/stars/conferences', { params })
  },
  fetchConferenceInfo(id) {
    return request.get(`/conferences/${id}`)
  },
  fetchContributionList(id, params) {
    return request.get(`/conferences/${id}/contributions`, { params })
  },
  addConferenceInfo(payload) {
    return request.post('/conferences', payload)
  },
  updateConferenceInfo(id, payload) {
    return request.put(`/conferences/${id}`, payload)
  },
  addConferenceMember(id, payload) {
    return request.post(`/conferences/${id}/members`, payload)
  },
  addConferenceContribution(id, payload) {
    return request.post(`/conferences/${id}/contributions`, payload)
  },
  addContributionInfo(id, payload) {
    return request.post(`/conferences/${id}/contributions`, payload)
  },
  fetchUserConferenceList(params) {
    return request.get(`/user/conferences`, { params })
  },
  fetchUserConferenceInfo(id) {
    return request.get(`/user/conferences/${id}`)
  },
  deleteUserConferenceInfo(id) {
    return request.delete(`/user/conferences/${id}`)
  },
  fetchUserContributionList(params) {
    return request.get(`/user/contributions`, { params })
  },
  fetchUserContributionInfo(id) {
    return request.get(`/user/contributions/${id}`)
  },
  deleteUserContributionInfo(confID, contributionID) {
    return request.delete(
      `/conferences/${confID}/contributions/${contributionID}`
    )
  },
  updateConferenceStarStatus(id, payload) {
    return request.post(`/conferences/${id}/star`, payload)
  },
  updateConferenceThumbupStatus(id, payload) {
    return request.post(`/conferences/${id}/thumbs-up`, payload)
  },
  updateConferencePV(id, payload) {
    return request.post(`/conferences/${id}/pv`, payload)
  },
  getConferenceUserStatus(id, params) {
    return request.get(`/conferences/${id}/check`, { params })
  },
}
