<template>
  <div class="about-container">
    <The-header fixed theme="light" />
    <div class="about-content">
      <nuxt />
    </div>
    <The-footer />
    <app-back-top></app-back-top>
  </div>
</template>
<script>
export default {
  name: 'AboutLayout',
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
@import '~/styles/hairline.scss';
.about-content {
  padding-top: 66px;
}

@media (max-width: 768px) {
  .about-content {
    padding-top: 44px;
  }
}
</style>
