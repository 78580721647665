<template>
  <div class="footer">
    <div class="w">
      <div class="footer__container">
        <div class="link-list">
          <span class="link-item" @click="linkAbout('partner')">友情链接</span>
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkAbout('aboutUs')">关于我们</span>
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkJoin">加入我们</span>
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkAbout('connectUs')"
            >联系我们</span
          >
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkVip">会员权益</span>
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkPrivacy">隐私条款</span>
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkOrganization">诊疗机构</span>
          <a-divider class="line-vertical" type="vertical" />
          <span class="link-item" @click="linkOrganization"
            >中国肿瘤心脏病学网</span
          >
        </div>
        <div class="qrcode__box">
          <div class="qrcode__item">
            <a-popover
              overlay-class-name="qrcode__popover"
              :get-popup-container="
                trigger => {
                  return trigger.parentNode || document.body
                }
              "
            >
              <template slot="content">
                <a
                  href="http://static.icardiooncology.com/assets/boka.png"
                  target="_blank"
                >
                  <img
                    class="qrcode__img"
                    src="http://static.icardiooncology.com/assets/boka.png"
                    alt=""
                  />
                </a>
              </template>
              <span class="qrcode__text live">
                <svg-icon icon-class="live" class="qrcode__icon"></svg-icon>
              </span>
            </a-popover>
          </div>
          <div class="qrcode__item">
            <a-popover
              overlay-class-name="qrcode__popover"
              :get-popup-container="
                trigger => {
                  return trigger.parentNode || document.body
                }
              "
            >
              <template slot="content">
                <a
                  href="http://static.icardiooncology.com/assets/wechat.png"
                  target="_blank"
                >
                  <img
                    class="qrcode__img"
                    src="http://static.icardiooncology.com/assets/wechat.png"
                    alt=""
                  />
                </a>
              </template>
              <span class="qrcode__text wechat">
                <svg-icon icon-class="wechat" class="qrcode__icon"></svg-icon>
              </span>
            </a-popover>
          </div>
          <div class="qrcode__item">
            <a-popover
              overlay-class-name="qrcode__popover"
              :get-popup-container="
                trigger => {
                  return trigger.parentNode || document.body
                }
              "
            >
              <template slot="content">
                <a
                  href="http://static.icardiooncology.com/assets/weibo.png"
                  target="_blank"
                >
                  <img
                    class="qrcode__img"
                    src="http://static.icardiooncology.com/assets/weibo.png"
                    alt=""
                  />
                </a>
              </template>
              <span class="qrcode__text weibo">
                <svg-icon icon-class="weibo" class="qrcode__icon"></svg-icon>
              </span>
            </a-popover>
          </div>
          <div class="qrcode__item">
            <a-popover
              overlay-class-name="qrcode__popover"
              :get-popup-container="
                trigger => {
                  return trigger.parentNode || document.body
                }
              "
            >
              <template slot="content">
                <a
                  href="http://static.icardiooncology.com/assets/zhihu.png"
                  target="_blank"
                >
                  <img
                    class="qrcode__img"
                    src="http://static.icardiooncology.com/assets/zhihu.png"
                    alt=""
                  />
                </a>
              </template>
              <span class="qrcode__text zhihu">
                <svg-icon icon-class="zhihu" class="qrcode__icon"></svg-icon>
              </span>
            </a-popover>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="footer__bottom-logo">
          <img src="~/assets/footer-icon.png" data-object-fit="cover" />
        </div>
        <div class="footer__bottom-left">
          <div class="footer__bottom-copyright">
            <span>
              Copyright © 2019-2020 三度医学 All right reserved.
              <a
                class="link"
                href="http://www.beian.miit.gov.cn/"
                target="blank"
              >
                沪ICP备19012791号-1
              </a>
            </span>
            <a
              class="beian link"
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202012374"
            >
              <img src="~/assets/beian.png" />
              <span>沪公网安备 31011202012374号</span>
            </a>
          </div>
          <div>
            <span>
              <a class="link" target="_blank" href="https://www.12377.cn/">
                有害信息举报专区
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="footer__bottom-mobile">
        <div class="footer__bottom-mobile-logo">
          <img src="~/assets/footer-icon.png" />
        </div>
        <p class="footer__bottom-mobile-item">
          Copyright © 2019-2020 三度医学All right reserved.
        </p>
        <p class="footer__bottom-mobile-item">
          <a class="link" target="_blank" href="https://www.12377.cn/">
            有害信息举报专区
          </a>
        </p>
        <p class="footer__bottom-mobile-item">
          <a class="link" href="http://www.beian.miit.gov.cn/" target="blank">
            沪ICP备19012791号-1
          </a>
        </p>
        <p class="footer__bottom-mobile-item">
          <a
            class="beian link"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202012374"
          >
            <img src="~/assets/beian.png" />
            <span>沪公网安备 31011202012374号</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import siteConfigService from '@/services/siteConfigurations'

export default {
  name: 'TheFooter',
  data() {
    return {
      sitConfigs: {},
    }
  },
  computed: {
    contactUS() {
      return this.sitConfigs.contactUS || {}
    },
    joinUS() {
      return this.sitConfigs.joinUS || {}
    },
    qrcode() {
      return this.sitConfigs.qrcode || {}
    },
  },
  created() {
    this.getConfig()
  },
  methods: {
    async getConfig() {
      const sitConfigs = await siteConfigService.fetchConfigurations()
      this.sitConfigs = sitConfigs || {}
    },
    linkAbout(name) {
      this.$router.push(`/about#${name}`)
    },
    linkJoin() {
      this.$router.push('/join')
    },
    linkVip() {
      this.$router.push('/vip')
    },
    linkPrivacy() {
      this.$router.push('/agreement/privacy')
    },
    linkOrganization() {
      this.$router.push('/organization')
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/variables.scss';
.footer {
  padding: 22px 20px 26px;
  color: #fff;
  background-color: #545454;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #fff;
      font-size: 14px;
      height: 76px;
    }
    .link-item {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .line-vertical {
      width: 1px;
      margin: 0 20px;
      background: #fff;
      height: 20px;
      top: 0;
    }

    .qrcode {
      &__box {
        display: flex;
      }

      &__item {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        a {
          cursor: zoom-in;
        }
      }

      &__img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }
      &__popover {
        ::v-deep {
          .ant-popover-inner-content {
            padding: 10px;
          }
        }
      }
      &__text {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #fff;
        background: #fff;
        text-align: center;
        transition: all 0.3s ease-out;
        cursor: pointer;
        &::after {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
        &.live:hover {
          border-color: $--color-primary;
          background: #fff;
          .qrcode__icon {
            color: $--color-primary;
          }
        }
        &.wechat:hover {
          border-color: #7bc549;
          background: #fff;
          .qrcode__icon {
            color: #7bc549;
          }
        }
        &.weibo:hover {
          border-color: #ff763b;
          background: #fff;
          .qrcode__icon {
            color: #ff763b;
          }
        }
        &.zhihu:hover {
          border-color: #0084ff;
          background: #fff;
          .qrcode__icon {
            color: #0084ff;
          }
        }
      }
      &__icon {
        display: inline-block;
        vertical-align: middle;
        transition: all 0.3s ease-out;
        color: #545454;
        font-size: 20px;
      }
    }
  }
  // .line-landscape {
  //   margin: 5px 0 18px;
  //   background-color: #fff;
  // }
  &__bottom {
    font-size: 12px;
    color: #fff;
    .bottom-vertical {
      background-color: #fff;
      margin: 0 20px;
    }
    .link {
      &:hover {
        text-decoration: underline;
      }
    }
    &-left {
      flex: 1;
      text-align: center;
      color: #999;
      // padding-top: 40px;
      a {
        color: #999;
      }
    }
    &-logo {
      width: 250px;
      height: 60px;
      img {
        width: 100%;
        vertical-align: middle;
        @include object-fit(contain);
      }
    }
    &-copyright {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .beian {
      display: flex;
      align-items: center;
      margin-left: 5px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    &-mobile {
      display: none;
      text-align: center;
      &-item {
        // color: #ccc;
        font-size: 12px;
        margin-top: 8px;
        margin-bottom: 0;
        color: #999;
        a {
          color: #999;
        }
      }
      .beian {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 14px;
          height: 14px;
        }
      }
      &-logo {
        text-align: center;
        img {
          width: 200px;
          vertical-align: middle;
          @include object-fit(contain);
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .footer {
    padding: 22px 20px 26px;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 30px 20px;
    height: auto;
    color: #fff;
    background-color: #545454;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__container {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
      .link-list {
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 13px;
        margin-top: 30px;
        // padding: 0 8px;
        height: auto;
        width: 100%;
      }
      .link-item {
        flex: 0 0 auto;
        width: 25%;
        text-align: center;
        margin-bottom: 5px;
      }
      .line-vertical {
        display: none;
      }
    }
    .line-landscape {
      margin: 20px 0 4px;
      background-color: #fff;
    }
    &__bottom {
      display: none;
      &-mobile {
        display: block;
      }
    }
  }
}
</style>

<style lang="scss">
.qrcode {
  &__popover {
    .ant-popover-inner-content {
      padding: 10px;
    }
  }
}
</style>
