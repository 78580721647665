import Vue from 'vue'

import _ from 'lodash'
import normalizr from '@/utilities/normalizr'
import weiboService from '@/services/weibo'

export const state = () => ({
  weiboIDList: [],
  myWeiboIDList: [],
  commentIDList: [],
  dataSource: {},
  pagination: {
    page: 1,
    perPage: 10,
  },
  count: 0,
  myPagination: {
    page: 1,
    perPage: 10,
  },
  myCount: 0,
})

export const getters = {
  weiboList(state) {
    return _.map(state.weiboIDList, id => state.dataSource[id])
  },
  myWeiboList(state) {
    return _.map(state.myWeiboIDList, id => state.dataSource[id])
  },
}

export const mutations = {
  setWeiboData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.weiboIDList =
      pagination.page === 1 ? idList : _.concat(state.weiboIDList, idList)
    state.pagination = pagination
    state.count = count

    _.assign(state.dataSource, normalizr(data))
  },
  setMyWeiboData(state, { data, pagination, count }) {
    const idList = _.map(data, 'id')

    state.myWeiboIDList =
      pagination.page === 1 ? idList : _.concat(state.myWeiboIDList, idList)
    state.myPagination = pagination
    state.myCount = count

    _.assign(state.dataSource, normalizr(data))
  },
  updateWeiboData(state, payload) {
    if (!_.includes(state.weiboIDList, payload.id)) {
      state.weiboIDList.push(payload.id)
    }
    Vue.set(
      state.dataSource,
      payload.id,
      _.assign({}, state.dataSource[payload.id], payload)
    )
  },
  deleteMyWeiboData(state, id) {
    if (!id) {
      return
    }

    state.myCount = state.myCount - 1

    state.myWeiboIDList = _.without(state.myWeiboIDList, id)
  },
}

export const actions = {
  getWeiboList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
    })
    return weiboService.fetchWeiboList(payload).then(response => {
      context.commit('setWeiboData', response)
    })
  },
  fetchMyWeiboList(context, payload) {
    payload = _.defaults(payload, {
      page: 1,
      perPage: 10,
    })

    return weiboService.fetchMyWeiboList(payload).then(response => {
      context.commit('setMyWeiboData', response)
    })
  },
  addWeiboInfo(context, payload) {
    return weiboService.addWeiboInfo(payload).then(response => {
      context.commit('updateWeiboData', response)
    })
  },
  deleteMyWeiboInfo(context, id) {
    return weiboService.deleteWeibo(id).then(() => {
      context.commit('deleteMyWeiboData', id)
    })
  },
}
