<template>
  <div :id="id" class="app-comment">
    <span v-if="isDelete" class="delete-btn" @click.stop="onDelete">
      <svg-icon icon-class="shanchu"></svg-icon>
    </span>
    <div class="app-comment-inner" @click="handleCardClick">
      <div class="app-popover-arrow"></div>
      <div class="app-comment-avatar">
        <slot name="avatar">
          <a-avatar
            class="app-comment-avatar-inner"
            :size="60"
            :src="avatar"
            icon="user"
            @click="$emit('avatarClick')"
          >
          </a-avatar>
        </slot>
      </div>
      <div class="app-comment-content">
        <div class="app-comment-content-author">
          <slot name="name">
            <span class="app-comment-content-author-name">
              {{ name }}
              <app-official
                v-if="isOfficial"
                :official="true"
                icon-class="renzheng"
              >
              </app-official>
              <app-official
                v-if="isDoctor"
                :official="false"
                icon-class="renzheng"
              >
              </app-official>
            </span>
          </slot>
        </div>
        <div class="app-comment-content-detail">
          <p>{{ detail }}</p>
          <slot name="detail"> </slot>
        </div>
        <div class="app-comment-footer">
          <span class="app-comment-footer-time">{{ time }}</span>
          <ul v-if="isShowComment" class="app-comment-actions">
            <li @click="toggleReply">
              <svg-icon
                class="icon"
                :class="showReply && 'active'"
                icon-class="message"
              ></svg-icon>
              <span class="comment-count">{{ commentCount }}</span>
            </li>
            <li @click="handleThumbUpClick">
              <svg-icon
                class="icon"
                icon-class="dianzan"
                :class="isThumbup > 0 && 'active'"
              ></svg-icon
              ><span class="comment-count">{{ thumbCount }}</span>
            </li>
            <li v-if="isShowStar" @click="handleStarClick">
              <svg-icon
                class="icon"
                icon-class="shoucang"
                :class="isStar > 0 && 'active'"
              ></svg-icon
              ><span class="comment-count">{{ starCount }}</span>
            </li>
            <li
              v-if="isShowShare"
              class="comment-share-pc"
              @click="toggleShareStatus"
            >
              <svg-icon
                class="icon"
                icon-class="fenxiang"
                :class="{ active: shareModal }"
              ></svg-icon>
              <div
                v-show="shareModal"
                class="share-modal"
                :class="{ active: shareModal }"
              >
                <div
                  class="social-share-cs share-container"
                  data-sites="wechat,qq,weibo"
                ></div>
              </div>
            </li>
            <li
              v-if="isShowShare && isWeiXin"
              class="comment-share-mobile"
              @click="weixinShareConfig"
            >
              <svg-icon
                class="icon"
                icon-class="fenxiang"
                :class="{ active: shareModal }"
              ></svg-icon>
            </li>
          </ul>
        </div>
        <div v-if="showReply" class="app-comment-form">
          <a-input v-model="text" class="app-comment-form-input">
            <div slot="addonBefore">
              <span class="reply">回复 &nbsp;</span>
              <span
                >{{ name }}
                <app-official
                  v-if="isOfficial"
                  :official="true"
                  icon-class="renzheng"
                >
                </app-official>
                <app-official
                  v-if="isDoctor"
                  :official="false"
                  icon-class="renzheng"
                >
                </app-official>
                :</span
              >
            </div>
          </a-input>
          <div class="app-comment-form-btn">
            <app-button class="btn" type="primary" @click="handleCommentClick"
              >提交</app-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-show="showReply" class="app-comment-nested">
      <slot></slot>
    </div>
    <!-- <a-modal
      :visible="shareMobileVisible"
      :footer="null"
      @ok="shareMobileVisible = false"
      @cancel="shareMobileVisible = false"
    >
      <img src="~/assets/share-tips.png" />
    </a-modal> -->
    <div
      v-if="shareMobileVisible"
      class="share-mask"
      @click="shareMobileVisible = false"
    >
      <img class="share-mask__img" src="~/assets/share-tips.png" />
    </div>
  </div>
</template>

<script>
import wechatService from '@/services/wechat'

export default {
  name: 'AppComment',
  props: {
    id: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    commentCount: {
      type: [String, Number],
      default: '',
    },
    starCount: {
      type: [String, Number],
      default: 0,
    },
    thumbCount: {
      type: [String, Number],
      default: 0,
    },
    isDoctor: {
      type: Boolean,
      default: false,
    },
    isOfficial: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isStar: {
      type: Boolean,
      default: false,
    },
    isThumbup: {
      type: Boolean,
      default: false,
    },
    isShowComment: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isShowStar: {
      type: Boolean,
      default: false,
    },
    isShowShare: {
      type: Boolean,
      default: false,
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showReply: this.isExpand,
      text: '',
      shareModal: false,
      shareMobileVisible: false,
    }
  },
  computed: {
    isWeiXin() {
      return wechatService.isWeiXin()
    },
  },
  methods: {
    toggleReply() {
      this.showReply = !this.showReply
      this.$emit('change', this.showReply)
    },
    handleCommentClick() {
      this.$emit('submit', this.text)
      this.text = ''
    },
    handleThumbUpClick() {
      this.$emit('thumbup')
    },
    handleStarClick() {
      this.$emit('star')
    },
    onDelete() {
      this.$emit('delete', this.id)
    },
    toggleShareStatus() {
      this.shareModal = !this.shareModal
      this.$emit('toggleShare', this.shareModal)
      // if (this.shareModal) {
      //   userService.shareCountUpdate(`/articles/${this.articleID}/forward`)
      //   this.$nextTick(() => {
      //     window.socialShare('.social-share-cs', {
      //       title: '三度医学',
      //       description: '三度医学',
      //       wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
      //       wechatQrcodeHelper:
      //         '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>',
      //     })
      //   })
      // }
    },
    handleCardClick() {
      this.$emit('click')
    },
    weixinShareConfig() {
      this.shareMobileVisible = true
      this.$emit('mobileShare')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.app-comment {
  position: relative;
  &-inner {
    display: flex;
    padding: 40px 0 40px 20px;
    border-top: 1px solid #f0f0f0;
  }
  .app-popover-arrow {
    display: none;
    left: 20px;
    top: -7px;
  }
  &-nested {
    margin-left: 100px;
    background-color: rgba(239, 241, 245, 0.7);
    .app-comment {
      &-inner {
        border-top-color: transparent;
        padding: 40px 20px;
      }
      &-form {
        &-input {
          background-color: #fff;
        }
        ::v-deep {
          .ant-input {
            background-color: #fff;
          }
        }
      }
    }
    .app-popover-arrow {
      display: block;
    }
  }
  &-avatar {
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    z-index: 1;
  }
  &-content {
    position: relative;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;
    padding-left: 20px;
    &-author {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 10px;
      font-size: 14px;
      &-name {
        font-size: 16px;
        font-weight: 500;
        color: #1a1a1a;
      }
      .renzheng {
        margin-left: 2px;
      }
    }
    &-detail {
      font-size: 14px;
      line-height: 1.57;
      margin-bottom: 14px;
      p {
        color: #666;
        margin-bottom: 0;
      }
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-time {
      font-size: 14px;
      color: #bbb;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      margin-left: 30px;
      cursor: pointer;
      position: relative;
    }
    .icon {
      color: #cccccc;
      &.active {
        color: $--color-primary;
      }
    }
    .comment-count {
      color: #9a9a9a;
      font-size: 14px;
      margin-left: 4px;
    }
  }
  &-form {
    margin-top: 26px;
    &-input {
      margin-bottom: 16px;
      resize: none;
      background-color: rgba(239, 241, 245, 0.7);
      .reply {
        color: #696969;
      }
    }
    ::v-deep {
      .ant-input-group-addon {
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: 500;
        color: #191919;
      }
      .ant-input {
        height: 50px;
        border: none;
        background-color: rgba(239, 241, 245, 0.7);
        &:focus {
          box-shadow: none;
        }
      }
    }
    &-btn {
      text-align: right;
    }
  }

  .share-modal {
    position: absolute;
    left: 18px;
    top: -50px;
    z-index: 101;
    &.active {
      visibility: visible;
    }
  }
}
.delete-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
  background: #f10909;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  .svg-icon {
    color: #fff;
  }
}

.comment-share-pc {
  display: block;
}

.comment-share-mobile {
  display: none;
}

.share-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  text-align: center;

  &__img {
    width: 80%;
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .app-comment {
    &-inner {
      padding: 16px 0 10px 9px;
    }
    .app-popover-arrow {
      display: none;
      left: 20px;
      top: -7px;
    }
    &-nested {
      margin-left: 10px;
      background-color: rgba(239, 241, 245, 0.7);
      .app-comment {
        &-inner {
          border-top-color: transparent;
          padding: 16px 0 10px 9px;
        }
        &-form {
          &-input {
            background-color: #fff;
          }
          ::v-deep {
            .ant-input {
              background-color: #fff;
            }
          }
        }
      }
      .app-popover-arrow {
        display: none;
      }
    }
    &-avatar {
      &-inner {
        width: 25px !important;
        height: 25px !important;
      }
    }
    &-content {
      padding-left: 10px;
      margin-left: -35px;
      // padding-right: 10px;
      &-author {
        margin-left: 35px;
        margin-bottom: 10px;
        font-size: 14px;
        &-name {
          font-size: 14px;
        }
        .renzheng {
          margin-left: 2px;
        }
      }
      &-detail {
        font-size: 12px;
        line-height: normal;
        margin-bottom: 8px;
        p {
          color: #666;
          margin-bottom: 0;
        }
      }
    }
    &-footer {
      &-time {
        font-size: 12px;
        font-weight: normal;
        color: #999;
      }
    }
    &-actions {
      li {
        margin-left: 15px;
      }
      .icon {
        color: #cccccc;
        &.active {
          color: $--color-primary;
        }
      }
      .comment-count {
        font-size: 12px;
      }
    }
    &-form {
      margin-top: 14px;
      &-input {
        margin-bottom: 10px;
        resize: none;
        background-color: rgba(239, 241, 245, 0.7);
        .reply {
          color: #696969;
        }
      }
      ::v-deep {
        .ant-input-group-addon {
          font-size: 12px;
          font-weight: 500;
        }
        .ant-input {
          height: 37px;
          background-color: rgba(239, 241, 245, 0.7);
        }
      }
      &-btn {
        text-align: right;
      }
    }
  }

  .comment-share-pc {
    display: none;
  }

  .comment-share-mobile {
    display: block;
  }
}
</style>
