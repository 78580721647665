<template>
  <button class="app-button" :class="themeClass" @click="handlerClick">
    <span class="app-button-inner">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themeClass() {
      return `app-button-${this.type} ${this.dashedClass}`
    },
    dashedClass() {
      return this.dashed ? `app-button-dashed` : ''
    },
  },
  methods: {
    handlerClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';

.app-button {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 0;
  color: #fff;
  background-color: #545454;
  border-color: #545454;
  min-width: 64px;
  border-radius: 3px;
  // &-inner {
  //   display: flex;
  //   align-items: center;
  //   text-align: center;
  // }
  &-default {
    background-color: #545454;
    border-color: #545454;
    color: #fff;
    &.app-button-dashed {
      background-color: #fff;
      color: #545454;
    }
  }
  &-primary {
    background-color: $--color-primary;
    border-color: $--color-primary;
    color: #fff;
    &.app-button-dashed {
      background-color: #fff;
      color: $--color-primary;
    }
  }
  &-info {
    background-color: #ccc;
    border-color: #ccc;
    color: #fff;
    &.app-button-dashed {
      background-color: #fff;
      color: #ccc;
    }
  }
  &-upload {
    background-color: #eff1f5;
    border-color: #eff1f5;
    color: #666;
    height: 34px;
    line-height: 34px;
    padding: 0 16px;
  }
  .anticon,
  .svg-icon {
    margin-left: 5px;
  }
}
.app-button,
.app-button:active,
.app-button:focus {
  outline: 0;
}
@media screen and (max-width: 768px) {
  .app-button {
    height: 22px;
    line-height: 22px;
    padding: 0 4px;
    font-size: 12px;
    min-width: 38px;
    &-upload {
      height: 23px;
      line-height: 23px;
      padding: 0 8px;
    }
    .anticon,
    .svg-icon {
      margin-left: 3px;
    }
  }
}
</style>
