import _ from 'lodash'
import {
  getToken,
  setToken,
  removeToken,
  removeHistoryKeys,
} from '@/services/token'
import userService from '@/services/user'
import authService from '@/services/auth'

import { AUTHTYPE, VERIFYSTATUS } from '@/utilities/constants'

const isLogedIn = !!getToken()

export const state = () => ({
  isLogedIn,
  userInfo: {
    id: '',
    nickname: '',
    avatarUrl: '',
    status: '',
    phone: '',
  },
  authenticationDataList: {},
  unreadMessageCount: 0,
})

export const getters = {
  doctorAuthticationData(state, getters) {
    const { status } = state.authenticationDataList[AUTHTYPE.DOCTOR] || {}
    return status === VERIFYSTATUS.CANCELED
      ? {}
      : state.authenticationDataList[AUTHTYPE.DOCTOR] || {}
  },
  doctorStudentAuthticationData(state, getters) {
    const { status } =
      state.authenticationDataList[AUTHTYPE.DOCTOR_STUDENT] || {}
    return status === VERIFYSTATUS.CANCELED
      ? {}
      : state.authenticationDataList[AUTHTYPE.DOCTOR_STUDENT] || {}
  },
  jobAuthticationData(state, getters) {
    const { status } = state.authenticationDataList[AUTHTYPE.JOB] || {}
    return status === VERIFYSTATUS.CANCELED
      ? {}
      : state.authenticationDataList[AUTHTYPE.JOB] || {}
  },
  educationAuthticationData(state, getters) {
    const { status } = state.authenticationDataList[AUTHTYPE.EDUCATION] || {}
    return status === VERIFYSTATUS.CANCELED
      ? {}
      : state.authenticationDataList[AUTHTYPE.EDUCATION] || {}
  },
  isDoctor(state) {
    return typeof state.userInfo.role === 'string'
      ? state.userInfo.role === AUTHTYPE.DOCTOR
      : _.intersection(state.userInfo.role, [
          AUTHTYPE.DOCTOR,
          AUTHTYPE.DOCTOR_STUDENT,
        ]).length > 0
  },
}

export const mutations = {
  mountUserInfo(state) {
    state.isLogedIn = true
    const userInfo = window.localStorage.getItem('sandu-userInfo')
    if (!userInfo) return
    try {
      state.userInfo = JSON.parse(userInfo)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },
  setUserInfo(state, payload) {
    state.isLogedIn = true
    state.userInfo = payload
    window.localStorage.setItem('sandu-userInfo', JSON.stringify(payload))
  },
  setUserToken(state, payload) {
    setToken(payload)
  },
  updateAccountInfo(state, payload) {
    state.userInfo = _.assign({}, state.userInfo, payload)
  },
  removeUserInfo(state) {
    state.isLogedIn = false
    state.userInfo = {}

    window.localStorage.removeItem('sandu-userInfo')
  },
  setAuthentications(state, data) {
    state.authenticationDataList = data || {}
  },
  updateAuthentication(state, payload) {
    if (!payload.id) {
      return
    }

    if (!_.includes(state.authenticationIDList, payload.id)) {
      state.authenticationIDList.push(payload.id)
    }
    _.assign(state.authenticationDataList[payload.id], payload)
  },
  updateUnreadMessageCount(state, messageCount) {
    state.unreadMessageCount = messageCount
  },
}

export const actions = {
  login(context, payload) {
    return authService.login(payload).then(response => {
      setToken(response.token)
      // 清除历史搜索记录
      removeHistoryKeys()
      context.commit('setUserInfo', response.data)
      return response
    })
  },
  logout(context) {
    removeToken()
    removeHistoryKeys()
    context.commit('removeUserInfo')
    Promise.resolve()
  },
  refreshUserInfo(context) {
    return userService.fetchUserInfo().then(response => {
      context.commit('setUserInfo', response)
      return response
    })
  },
  getAuthentications(context, payload) {
    return authService.fetchAuthentications().then(response => {
      context.commit('setAuthentications', response)
      return response
    })
  },
  updateAuthentications(context, payload) {
    return authService.updateAuthentications(payload).then(response => {
      context.dispatch('getAuthentications')
      return response
    })
  },
  updatePersonalUserInfo(context, payload) {
    return userService.updateUserInfo(payload).then(() => {
      context.commit('updateAccountInfo', payload)
    })
  },
  updateEnterpriseUserInfo(context, payload) {
    return userService.updateEnterpriseUserInfo(payload).then(() => {
      context.commit('updateAccountInfo', payload)
    })
  },
  getMessageCount(context) {
    return userService.fetchUserUnReadMessages().then(res => {
      context.commit('updateUnreadMessageCount', res.count)
    })
  },
}
