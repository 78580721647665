<template>
  <div class="app-title">
    <h2 class="app-title__title" :style="{ color: titleColor }">
      <span class="app-title__ornament">//</span>{{ title }}
    </h2>
    <div class="app-title__btn" :style="btnColorStyle" @click="handleMoreClick">
      {{ btnText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTitle',

  props: {
    title: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: '#333',
    },
    btnText: {
      type: String,
      default: '查看更多>',
    },
    btnColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    btnColorStyle() {
      if (this.btnColor) {
        return { color: this.btnColor }
      }
      return {}
    },
  },
  methods: {
    handleMoreClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';

.app-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  &__ornament {
    margin-right: 8px;
  }

  &__title {
    padding-bottom: 4px;
    border-bottom: 5px solid $--color-primary;
    margin-bottom: 0;
    line-height: 36px;
    font-size: 26px;
    font-weight: 600;
    color: #333;
  }

  &__btn {
    min-width: 120px;
    font-size: 12px;
    line-height: 32px;
    color: #fff;
    cursor: pointer;
    background: $--color-primary;
    border-radius: 3px;
  }
}

@media (max-width: 768px) {
  .app-title {
    padding: 20px 0;
    align-items: center;

    &__ornament {
      margin-right: 0;
    }

    &__title {
      padding-bottom: 0;
      border-bottom: 0;
      line-height: 28px;
      font-size: 20px;
    }

    &__btn {
      font-size: 12px;
      text-align: right;
      color: #999;
      background: transparent;
    }
  }
}
</style>
