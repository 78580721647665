import _ from 'lodash'
let currentScrollTop = 0
const onScroll = function() {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  const clientHeight =
    document.documentElement.clientHeight || document.body.clientHeight
  const maxScrollHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight
  if (Math.abs(currentScrollTop - scrollTop) < 30) {
    return
  }
  if ((scrollTop + clientHeight) / maxScrollHeight >= 0.95) {
    if (this.expression) {
      this.expression()
    }
  }
  currentScrollTop = scrollTop
}

const doBind = function() {
  const directive = this
  directive.scrollListener = _.throttle(onScroll.bind(directive), 300)
  document.addEventListener('scroll', directive.scrollListener)
  this.vm.$on('hook:beforeDestroy', function() {
    document.removeEventListener('scroll', directive.scrollListener)
  })
}

export default {
  bind(el, binding, vnode) {
    el.ctx = {
      el,
      vm: vnode.context,
      expression: binding.value,
    }
    el.ctx.vm.$on('hook:mounted', () => {
      el.ctx.vm.$nextTick(() => {
        doBind.call(el.ctx)
      })
    })
  },
  unbind(el, binding) {
    document.removeEventListener('scroll', el.ctx.scrollListener)
  },
}
