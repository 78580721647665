const key = 'sandu_web_accessToken'

const historySearch = 'sandu_web_search'

export function setToken(value) {
  if (process.client) {
    window.localStorage.setItem(key, value)
  }
}
export function setHistoryKeys(value) {
  if (process.client) {
    window.localStorage.setItem(historySearch, value)
  }
}

export function getHistoryKeys() {
  if (process.client) {
    return window.localStorage.getItem(historySearch)
  }

  return ''
}

export function removeHistoryKeys() {
  if (process.client) {
    return window.localStorage.removeItem(historySearch)
  }
}

export function getToken() {
  if (process.client) {
    if (process.env.MOCK_TOKEN) {
      return process.env.MOCK_TOKEN
    }

    return window.localStorage.getItem(key)
  }

  return ''
}

export function removeToken() {
  if (process.client) {
    window.localStorage.removeItem(key)
  }
}
