<template>
  <div class="load-more">
    <a-spin v-if="loading" />
    <span
      v-else-if="!loading && !noMore"
      class="load-more-text"
      @click="loadMore"
      >{{ text }}</span
    >
    <span v-else-if="!loading && noMore" class="load-more-noMore">{{
      noMoreText
    }}</span>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '点击加载更多',
    },
    noMore: {
      type: Boolean,
      default: false,
    },
    noMoreText: {
      type: String,
      default: '没有更多了',
    },
  },
  methods: {
    loadMore() {
      this.$emit('load-more')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
.load-more {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  &-text {
    font-size: 14px;
    color: #999;
    cursor: pointer;
  }
  &-noMore {
    font-size: 14px;
    color: #999;
  }
}
@media (max-width: 768px) {
  .load-more {
    padding: 25px 0;
    &-text {
      font-size: 12px;
    }
    &-noMore {
      font-size: 12px;
    }
  }
}
</style>
