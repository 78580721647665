<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'default',
      required: false,
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    colorClass() {
      return this.color ? `icon-${this.color}` : ''
    },
    svgClass() {
      if (this.className) {
        return `svg-icon ${this.colorClass} ${this.className}`
      } else {
        return `svg-icon ${this.colorClass}`
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/variables.scss';
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.icon-default {
  color: $--icon-color;
}
.icon-primary {
  color: $--color-primary;
}
.icon-success {
  color: $--color-success;
}
.icon-warning {
  color: $--color-warning;
}
.icon-danger {
  color: $--color-danger;
}
</style>
