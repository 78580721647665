export const GENDER = [
  { label: '男', value: 'MALE' },
  { label: '女', value: 'FEMALE' },
]

// 认证类型
export const AUTHTYPE = {
  EDUCATION: 'EDUCATION',
  JOB: 'JOB',
  DOCTOR: 'DOCTOR',
  DOCTOR_STUDENT: 'DOCTOR_STUDENT',
}

// 属性配置
export const FIELDCONFIG = {
  JOB_LEVEL: 'JOB_LEVEL',
  PROFESSIONAL: 'PROFESSIONAL',
}

export const ARTICLEKIND = {
  NEWS: 'NEWS',
  LIBRARY: 'LIBRARY',
  SPECIAL_COLUMN: 'SPECIAL_COLUMN',
  DRAFT: 'DRAFT',
  ALL: 'ALL',
}

export const VERIFYSTATUS = {
  EDITING: 'EDITING',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
}

export const STARTYPE = {
  ARTICLE: 'ARTICLE',
  SPECIAL_COLUMN: 'SPECIAL_COLUMN',
  CONFERENCE: 'CONFERENCE',
  WEIBO: 'WEIBO',
}

export const ARTICLESTYLE = {
  LARGE_BLOCK: 'LARGE_BLOCK',
  SMALL_BLOCK: 'SMALL_BLOCK',
  LONG_BLOCK: 'LONG_BLOCK',
}

export const PLATFORM = {
  PC: 'PC',
  MOBILE: 'MOBILE',
  ALL: 'ALL',
}
