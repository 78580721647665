<template>
  <div class="personal-center-title" :style="bgStyle">
    <span class="title-left">{{ title }}</span>
    <slot name="default">
      <div v-if="rightTitle" class="title-right">
        <slot name="postfix"></slot>
        <span @click="$emit('action')">{{ rightTitle }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'PersonalCenterTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    rightTitle: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'rgba(84, 84, 84, 0.08)',
    },
  },
  computed: {
    bgStyle() {
      return { background: this.bgColor }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.personal-center-title {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  background: rgba(84, 84, 84, 0.08);
  align-items: center;
  .title-left {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .title-right {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $--color-primary;
    font-weight: 500;
    cursor: pointer;
  }
}
@media screen and (max-width: 768px) {
  .personal-center-title {
    padding: 7px 18px;
    background: rgba(84, 84, 84, 0.08);
    .title-left {
      font-size: 12px;
    }
    .title-right {
      font-size: 12px;
    }
  }
}
</style>
