import _ from 'lodash'
import axios from 'axios'

import request from './request'

const uploadHost = process.env.UPLOAD_HOST

const store = {
  host: '',
  assetsPrefix: '',
  keyPrefix: '',
  expiration: '',
  certificate: {
    ossAccessKeyId: '',
    policy: '',
    signature: '',
  },
}

const isExpired = function isExpired(date, period = 1) {
  const target = new Date(date)

  return Date.now() >= target.getTime() - period * 60 * 1000
}

export const randomString = function randomString(len = 13) {
  const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'

  return _.join(
    _.map(_.range(0, len), () => str[_.random(str.length - 1)]),
    ''
  )
}

export function getUploadConfig() {
  if (store.expiration) {
    const hasExpired = isExpired(store.expiration)

    if (!hasExpired) {
      return Promise.resolve(store)
    }
  }

  return request.get(uploadHost).then(response => {
    _.assign(store, _.pick(response, _.keys(store)))

    return store
  })
}

export default {
  upload(context) {
    const file = context.file

    return getUploadConfig().then(config => {
      const { host, keyPrefix, assetsPrefix, certificate } = config
      const ext = _.last(_.split(file.name, '.'))
      const slug = randomString()
      const filename = `${keyPrefix}${slug}.${ext}`

      const formData = new FormData()

      formData.append('Cache-Control', 'public, max-age=31536000')

      _.forEach(certificate, (value, key) => {
        formData.append(key, value)
      })

      formData.append('key', filename)

      if (file instanceof File) {
        formData.append('file', file)
      } else if (file.blob) {
        formData.append('file', file.blob)
      }

      return axios.post(`//${host}`, formData).then(() => {
        const url = `${assetsPrefix}/${filename}`

        const result = {
          ossName: filename,
          originalName: file.name,
          url,
          thumbUrl: url,
          name: filename,
          status: 'done',
        }

        context.onSuccess && context.onSuccess(result)
        return result
      })
    })
    // .catch(err => {
    //   // eslint-disable-next-line no-console
    //   console.error(err)
    // })
  },
}
