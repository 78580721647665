<template>
  <div>
    <div class="pie">
      <div class="pie__item pie__item--orange"></div>
      <div class="pie__item pie__item--red"></div>
      <div class="pie__item pie__item--light-blue"></div>
      <div class="pie__item pie__item--pink"></div>
      <div class="pie__item pie__item--blue"></div>
      <div class="pie__item pie__item--green"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPie',
}
</script>

<style lang="scss" scoped>
.pie {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 160px;
    height: 160px;
    background: #fff;
    border-radius: 50%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
  }

  &__item {
    height: 100%;
    width: 50%;
    border-radius: 0 100% 100% 0/50%;
    margin-left: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left;

    &--red {
      background: #ff6029;
      transform: rotate(0.16turn);
    }

    &--light-blue {
      background: #85cfd2;
      transform: rotate(0.32turn);
    }

    &--pink {
      background: #ff8698;
      transform: rotate(0.48turn);
    }

    &--blue {
      background: #1da582;
      transform: rotate(0.64turn);
    }

    &--green {
      background: #84da15;
      transform: rotate(0.8turn);
    }

    &--orange {
      background: #feca38;
      transform: rotate(0.96turn);
    }
  }
}
</style>
