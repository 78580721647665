<template>
  <transition name="fade">
    <div
      v-show="visible"
      :style="{
        right: styleRight,
      }"
      class="elevator"
    >
      <div class="elevator__item" @click.stop="handleClick">
        <svg-icon class="icon" icon-class="back_up"></svg-icon>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from 'lodash'

const cubic = value => value ** 3
const easeInOutCubic = value =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2

export default {
  name: 'Elevator',

  props: {
    visibilityHeight: {
      type: Number,
      default: 200,
    },
    target: {
      type: String,
      default: '',
    },
    right: {
      type: Number,
      default: 20,
    },
    bottom: {
      type: Number,
      default: 40,
    },
  },

  data() {
    return {
      el: null,
      container: null,
      visible: false,
    }
  },

  computed: {
    styleBottom() {
      return `${this.bottom}px`
    },
    styleRight() {
      return `${this.right}px`
    },
  },
  beforeDestroy() {
    this.container.removeEventListener('scroll', this.throttledScrollHandler)
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
      this.throttledScrollHandler = _.throttle(this.onScroll, 300)
      this.container.addEventListener('scroll', this.throttledScrollHandler)
    })
  },
  methods: {
    init() {
      this.container = document
      this.el = document.documentElement
      if (this.target) {
        this.el = document.querySelector(this.target)
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`)
        }
        this.container = this.el
      }
    },
    onScroll() {
      const scrollTop = this.el.scrollTop
      this.visible = scrollTop >= this.visibilityHeight

      // if (scrollTop < this.visibilityHeight) {
      //   this.isShowMainServiceInfo = false
      // }
    },
    handleClick(e) {
      this.scrollToTop()
      this.$emit('click', e)
    },
    scrollToTop() {
      const el = this.el
      const beginTime = Date.now()
      const beginValue = el.scrollTop
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
          rAF(frameFunc)
        } else {
          el.scrollTop = 0
        }
      }

      rAF(frameFunc)
    },
  },
}
</script>

<style lang="scss" scoped>
.elevator {
  border-radius: 15px;
  cursor: pointer;
  z-index: 5;
  &__item {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 24px;
      color: #999;
    }
  }
}

@media (max-width: 768px) {
  .elevator {
    display: none !important;
  }
}
</style>
