<template>
  <div class="app-course-card" @click="goToDetailPage">
    <div class="app-course-card-avatar image-radius">
      <img v-lazy="compressImg(avatar, '248')" data-object-fit="cover" />
    </div>
    <div class="app-course-card-title">{{ title }}</div>
    <p class="app-course-card-desc">{{ content }}</p>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'AppCourseCard',
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
  },
  mounted() {
    _.delay(() => {
      window.objectFitPolyfill()
    }, 1000)
  },
  methods: {
    goToDetailPage() {
      this.$router.push(`/article-detail/${this.id}?kind=LIBRARY`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
.app-course-card {
  padding: 18px 10px;
  border: 1px solid transparent;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid #545454;
  }
  &-avatar {
    width: 100%;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }
  &-title {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.5;
    color: #1a1a1a;
    margin-top: 20px;
    margin-bottom: 6px;
    @include text-ellipsis(1);
  }
  &-desc {
    font-size: 12px;
    line-height: 1.82;
    color: #666;
    margin-bottom: 0;
    @include text-ellipsis(1);
  }
}

@media (max-width: 768px) {
  .app-course-card {
    padding: 0 0 20px;
    &-avatar {
      width: 100%;
      height: 82px;
    }
    &-title {
      font-size: 16px;
      color: #1a1a1a;
      margin-top: 20px;
      @include text-ellipsis(1);
    }
    &-desc {
      font-size: 12px;
      color: #999;
      margin-bottom: 0;
      @include text-ellipsis(1);
    }
  }
}
</style>
