<template>
  <a-tooltip placement="top">
    <template slot="title">
      <span>{{
        official ? '三度医学官方团队帐号' : '医生/医学生认证帐号'
      }}</span>
    </template>
    <span :class="iconClass" class="official-icon">
      <svg-icon v-if="official" icon-class="renzheng2"></svg-icon>
      <svg-icon v-else icon-class="renzheng"></svg-icon>
    </span>
  </a-tooltip>
</template>

<script>
export default {
  name: 'AppOfficial',
  props: {
    official: {
      type: Boolean,
      default: true,
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
.official-icon {
  margin-left: 4px;
}
</style>
