<template>
  <div :class="`app-goods app-goods-${size}`" :style="{ width }">
    <div class="app-goods__thumbnail image-radius">
      <img
        v-lazy="compressImg(dataSource.thumbnail, '255')"
        data-object-fit="cover"
        class="app-goods__img"
      />
      <div class="app-goods-mask image-radius"></div>
    </div>
    <span
      v-if="tag"
      class="app-goods__tag"
      @click.stop="handleTagClick(firstTag)"
      >{{ firstTag }}</span
    >
    <div class="app-goods__body">
      <h3 v-if="isHtml" class="app-goods__title" v-html="dataSource.title"></h3>
      <h3 v-else class="app-goods__title">{{ dataSource.title }}</h3>
      <div class="app-goods__user">
        <slot class="content">
          <div class="app-goods__user__left">
            <img
              v-lazy="
                dataSource.user.avatar
                  ? compressImg(dataSource.user.avatar, '255')
                  : getUserIcon(dataSource.userInfo)
              "
              alt=""
              class="app-goods__user-avatar"
            />
            <span class="app-goods__user-name">
              {{ dataSource.user && dataSource.user.name }}
              <app-official
                v-if="isOfficial"
                :official="true"
                icon-class="renzheng"
              >
              </app-official>
              <app-official
                v-if="isDoctor"
                :official="false"
                icon-class="renzheng"
              >
              </app-official>
            </span>
          </div>
          <div class="app-goods__time-mobile">{{ dataSource.time }}</div>
        </slot>
      </div>
      <div class="app-goods__auxiliary">
        <div class="app-goods__time">{{ dataSource.time }}</div>
        <div class="app-goods__conment">
          <span class="app-goods__num">{{ dataSource.num }}</span>
          <svg-icon
            class="app-goods__conment-icon"
            icon-class="message"
          ></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
export default {
  name: 'AppGoods',
  props: {
    dataSource: {
      type: Object,
      default: () => {
        return {}
      },
    },
    width: {
      type: String,
      default: '250px',
    },
    tag: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    isDoctor: {
      type: Boolean,
      default: false,
    },
    isOfficial: {
      type: Boolean,
      default: false,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    firstTag() {
      return _.head(this.dataSource.tags)
    },
  },
  methods: {
    handleTagClick(text) {
      this.$emit('tagClick', text)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
@import '~/styles/hairline.scss';

.app-goods {
  position: relative;
  display: inline-block;
  margin-top: 40px;
  width: 250px;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  cursor: pointer;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.03);
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
  &-mask {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
  }
  &.app-goods-large {
    width: 50%;
    height: 265px !important;
    .app-goods {
      &__thumbnail {
        height: 100%;
      }
      // &__tag {
      //   top: 10px;
      // }
      &__body {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px 20px;
        z-index: 10;
      }
      &__title {
        height: auto;
        color: #fff;
        margin-bottom: 8px;
        @include line(2);
      }
      &__user {
        margin-bottom: 4px;
        &-name {
          color: #fff;
        }
      }
      &__auxiliary {
        color: #fff;
      }
      &__conment {
        color: #fff;
        &-icon {
          color: #fff;
        }
      }
      &-mask {
        display: block;
      }
    }
  }
  &__thumbnail {
    height: 146px;
  }
  &__tag {
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 1px 5px;
    min-width: 44px;
    max-width: 90%;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__img {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }

  &__body {
    padding: 8px 16px;
  }

  &__title {
    display: block;
    font-size: 16px;
    font-weight: normal;
    color: #333333;
    margin-bottom: 8px;
    height: 48px;
    @include line(2);
  }
  &__time {
    &-mobile {
      display: none;
    }
  }
  &__user {
    margin-bottom: 8px;
    .renzheng {
      font-size: 12px;
      margin-left: 2px;
    }
  }

  &__user-avatar {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: #ccc;
  }

  &__user-name {
    font-size: 12px;
    color: $--color-primary;
    font-weight: 600;
  }

  &__auxiliary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    font-size: 12px;
  }
  &__conment {
    display: flex;
    align-items: center;
    color: #ccc;
    font-size: 12px;
    &-icon {
      color: #ccc;
      font-size: 12px;
      margin-left: 4px;
    }
  }
}

@media (max-width: 768px) {
  .app-goods {
    display: flex;
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 20px;
    width: 100% !important;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
    // &:last-child {
    //   margin-bottom: 0;
    // }
    &.app-goods-large {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      height: auto !important;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
      .app-goods {
        &__thumbnail {
          width: 100%;
          height: 175px;
          position: relative;
        }
        &__tag {
          top: 5px;
          max-width: 95px;
        }
        &__body {
          position: static;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0;
          width: 100%;
        }
        &__title {
          color: #333;
          margin-bottom: 0;
          margin-top: 0;
          padding: 15px;
          max-height: 66px;
          white-space: normal;
          @include line(2);
        }
        &__user {
          margin-bottom: 15px;
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-name {
            color: $--color-primary;
          }
          &-avatar {
            display: inline-block;
          }
        }
        &__time {
          &-mobile {
            float: right;
          }
        }

        &__auxiliary {
          color: #fff;
        }
        &__conment {
          color: #fff;
          &-icon {
            color: #fff;
          }
        }
        // &-mask {
        //   display: none;
        // }
      }
    }
    &__thumbnail {
      width: 105px;
      height: 75px;
      flex: 0 0 auto;
    }
    &__tag {
      left: 4px;
      top: 4px;
      min-width: 41px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 0 4px;
      max-width: 95px;
      font-size: 10px;
    }
    &__body {
      padding: 0 10px;
      flex: 1;
      width: 0;
    }

    &__title {
      margin-top: 8px;
      margin-bottom: 8px;
      line-height: 1.3;
      font-size: 14px;
      height: 36px;
    }
    &__time {
      &-mobile {
        display: block;
        font-size: 12px;
        color: #999;
      }
    }
    &__user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0px;
    }

    &__user-avatar {
      display: none;
    }

    &__user-name {
      font-size: 12px;
      color: $--color-primary;
      font-weight: normal;
    }

    &__auxiliary {
      display: none;
    }
  }
}
</style>
