<template>
  <div class="app-step">
    <div class="app-step__line">
      <svg-icon icon-class="time" class="time-icon"></svg-icon>
    </div>
    <div class="app-step__main">
      <div class="app-step__title" @click="handleClick">
        {{ dataSource.userInfo && dataSource.userInfo.nickname }}
        <app-official
          v-if="dataSource.userInfo && dataSource.userInfo.isOfficial"
          :official="true"
          icon-class="renzheng"
        >
        </app-official>
        <app-official
          v-if="dataSource.userInfo && isAuthDoctor(dataSource.userInfo.role)"
          :official="false"
          icon-class="renzheng"
        >
        </app-official
        >：
      </div>
      <div class="app-step__text" @click="handleConClick">
        {{ dataSource.text }}
      </div>
      <div
        v-if="
          (dataSource.pictures && dataSource.pictures.length) ||
            (dataSource.video && dataSource.video.url)
        "
        class="app-step__banner"
        @click="handleConClick"
      >
        <a-row :gutter="8">
          <a-col
            v-for="(item, index) in dataSource.pictures"
            :key="index"
            class="app-step__banner__item"
            :span="8"
          >
            <img
              v-lazy="item.url"
              alt=""
              class="app-step__banner__img image-radius"
            />
          </a-col>
          <a-col
            v-if="dataSource.video && dataSource.video.url"
            class="app-step__banner__item"
            :span="12"
          >
            <video
              :src="dataSource.video.url"
              controls
              alt=""
              class="app-step__banner__img"
            />
          </a-col>
        </a-row>
        <!-- <div
          v-for="(item, index) in dataSource.pictures"
          :key="index"
          class="app-step__banner__item"
        >
          <img :src="item.url" alt="" class="app-step__banner__img" />
        </div> -->
      </div>
      <div class="app-step__decoration">
        {{ timeAgo(dataSource.createdAt) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppStep',
  props: {
    dataSource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    handleClick() {
      this.$emit('avatarClick')
    },
    handleConClick() {
      this.$emit('conClick')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
@import '~/styles/hairline.scss';

.app-step {
  padding: 0 10px 25px 41px;
  position: relative;

  &__line {
    position: absolute;
    left: 0;
    top: 0;
    width: 19px;
    height: 100%;
    text-align: center;
    &::after {
      position: absolute;
      left: 50%;
      top: 23px;
      content: '';
      width: 1px;
      transform: scaleX(0.5) translateX(-50%);
      height: calc(100% - 19px - 4px);
      background: $--color-primary;
    }

    &::before {
      position: absolute;
      left: 50%;
      top: 19px;
      content: '';
      transform: translateX(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      border: 1px solid $--color-primary;
    }
    .time-icon {
      color: $--color-primary;
      font-size: 12px;
    }
  }

  &__main {
  }

  &__banner {
    cursor: pointer;
    margin-bottom: 10px;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;

    &__item {
      // width: 80px;
      height: 80px;
      overflow: hidden;
      margin-bottom: 8px;
    }

    &__img {
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 12px;
    color: #333333;
    cursor: pointer;
  }

  &__text {
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    margin-bottom: 10px;
    cursor: pointer;

    @include line(3);
  }

  &__decoration {
    color: #999;
    font-size: 12px;
  }
}
</style>
