import _ from 'lodash'
import siteService from '@/services/siteConfigurations'
import { FIELDCONFIG } from '@/utilities/constants'

export const state = () => ({
  configInfo: {},
  fieldConfig: {},
})

export const mutations = {
  setAppConfigInfo(state, payload) {
    state.configInfo = payload
  },
  setFieldConfig(state, payload) {
    state.fieldConfig = payload
  },
}

export const actions = {
  getAppConfigInfo(context) {
    return siteService.fetchConfigurations().then(response => {
      context.commit('setAppConfigInfo', response)
    })
  },
  getAllFieldConfigList(context) {
    const actions = _.map(_.values(FIELDCONFIG), category => {
      return siteService.fetchFieldConfig(category)
    })
    return Promise.all(actions).then(res => {
      context.commit('setFieldConfig', _.zipObject(_.keys(FIELDCONFIG), res))
    })
  },
}
