<template>
  <div class="meeting-list-item">
    <div class="meeting-list-item-meta">
      <div class="meeting-list-item-meta-avatar">
        <div
          v-if="tag"
          class="meeting-list-item-tag"
          :class="`meeting-list-item-tag-${type}`"
        ></div>
        <slot name="avatar">
          <img
            v-lazy="compressImg(avatar, '280')"
            class="image-radius"
            data-object-fit="cover"
          />
        </slot>
      </div>
      <div class="meeting-list-item-meta-content">
        <h4 class="meeting-list-item-meta-title">
          {{ title }}
        </h4>
        <div
          class="meeting-list-item-meta-description"
          v-html="description"
        ></div>
        <div class="meeting-list-item-meta-time">
          <span>{{ time }}</span>
          <span
            v-show="showAction"
            class="delete-btn-mobile"
            @click.stop="onDelete"
          >
            <svg-icon icon-class="shanchu"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <div v-if="showAction" class="meeting-list-item-action">
      <slot name="action">
        <span class="delete-btn" @click.stop="onDelete">
          <svg-icon icon-class="shanchu"></svg-icon>
        </span>
        <span v-if="isEdit" class="edit-btn" @click.stop="onEdit">编辑</span>
      </slot>
    </div>
    <div v-if="isEdit" class="meeting-list-item-action-mobile--top">
      <div class="edit-btn-wrapper" @click.stop="onEdit">
        编辑
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingListItem',
  props: {
    id: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'info',
    },
    time: {
      type: String,
      default: '',
    },
  },
  methods: {
    onDelete() {
      this.$emit('delete', this.id)
    },
    onEdit() {
      this.$emit('edit', this.id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.meeting-list-item {
  position: relative;
  padding: 0;
  cursor: pointer;
  // width: 270px;
  // height: 300px;
  box-shadow: 0 2px 30px 0 rgba(17, 38, 166, 0.05);
  background-color: #fff;
  margin: 0 0 40px;
  &:hover {
    box-shadow: 0 0 30px 0 rgba(17, 38, 166, 0.15);
    .meeting-list-item-action {
      display: block;
    }
  }
  &-meta {
    &-avatar {
      background-color: rgba(84, 84, 84, 0.08);
      width: 100%;
      height: 186px;
      img {
        width: 100%;
        height: 100%;
        @include object-fit(cover);
      }
    }
    &-content {
      // flex: 1 0;
      padding: 0 18px 18px;
    }
    &-title {
      margin-top: 16px;
      margin-bottom: 6px;
      color: #333;
      font-size: 14px;
      line-height: 22px;
      font-weight: normal;
      @include ellipsis;
    }
    &-time {
      display: none;
    }
    &-description {
      color: #999;
      font-size: 12px;
      height: 36px;
      line-height: 1.5;
      @include text-ellipsis(2);
      // margin-bottom: 42px;
    }
  }
  &-action {
    display: none;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: transparent;
  }
  .edit-btn {
    position: absolute;
    display: inline-block;
    left: 18px;
    bottom: 10px;
    width: 50px;
    height: 20px;
    line-height: 20px;
    background-color: #545454;
    color: #fff;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
  .delete-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: #fff;
    border-radius: 12px;
    background: #f10909;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      color: #fff;
    }
  }
  &-tag {
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background: url('../../assets/tag-info.png') no-repeat center/cover;
    &-info {
      background-image: url('../../assets/tag-info.png');
    }
    &-success {
      background-image: url('../../assets/tag-success.png');
    }
    &-error {
      background-image: url('../../assets/tag-error.png');
    }
  }
}

.meeting-list-item-action-mobile--top {
  display: none;
}
@media screen and (max-width: 768px) {
  .meeting-list-item {
    cursor: pointer;
    // width: 162px;
    // height: 300px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin: 0 0 20px;
    &-meta {
      position: relative;
      &-avatar {
        img {
          width: 100%;
          height: 100px;
        }
      }
      &-content {
        padding: 0 10px 10px;
      }
      &-title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 1.5;
        // height: 38px;
        // @include text-ellipsis(2);
      }
      &-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #999;
        margin-bottom: 10px;
        line-height: 20px;
      }
      &-description {
        margin-bottom: 4px;
      }
    }
    &-action {
      display: none !important;

      &-mobile {
        &--top {
          position: absolute;
          right: 6px;
          top: 3px;
          display: block;
        }
      }
    }
    .delete-btn-mobile {
      width: 20px;
      height: 20px;
      font-size: 12px;
      color: #fff;
      border-radius: 10px;
      background: #f10909;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.8);
      .svg-icon {
        color: #fff;
      }
    }
    &-tag {
      transform: scale(0.7);
      left: -7px;
      top: -5px;
    }
  }

  .edit-btn-wrapper {
    text-align: center;
    display: inline-block;
    padding: 3px;
    background-color: #545454;
    font-size: 12px;
    line-height: 1;
    color: #fff;
  }
}
</style>
