<template>
  <div class="app-banner">
    <div class="app-banner-content">
      <slot>
        <h3 v-if="title" class="app-banner-content-title">{{ title }}</h3>
        <!-- <span v-if="title" class="app-banner-content-line"></span> -->
      </slot>
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBanner',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
.app {
  &-banner {
    position: relative;
    width: 100%;
    height: 210px;
    background: url('../../assets/banner1.jpg') no-repeat center/cover;
    &-content {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        padding-top: 84px;
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 15px;
      }
      &-line {
        width: 90px;
        height: 3px;
        background-color: #ffffff;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .app {
    &-banner {
      height: 200px;
      &-content {
        &-title {
          padding-top: 75px;
          font-size: 21px;
          margin-bottom: 12px;
        }
        &-line {
          width: 45px;
          height: 1px;
        }
      }
    }
  }
}
</style>
