<template>
  <div class="user-layout">
    <The-header fixed />
    <nuxt />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'UserLayout',
  data() {
    return {
      isOpen: true,
    }
  },
  computed: {
    ...mapState({
      slideBarIsOpen: state => state.setting.slideBarIsOpen,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.scss';
@import '~/styles/hairline.scss';

.user-layout {
  width: 100%;
  height: 100vh;
  padding-top: 66px;
  box-sizing: border-box;
  overflow: hidden;
  background: url('../assets/login-bg.png') no-repeat center/cover;
}

@media screen and (max-width: 768px) {
  .user-layout {
    padding-top: 44px;
    background-image: url('../assets/login-bg-mobile.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
  }
}
</style>
