<template>
  <div
    ref="fixed"
    class="fixed__container"
    :class="isFixed ? 'fixed__container--fixed' : ''"
    :style="style"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Fixed',
  props: {
    top: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isFixed: false,
      offsetTop: 0,
      style: {
        width: 'auto',
        left: 0,
        top: 0,
      },
    }
  },
  mounted() {
    const el = this.$refs.fixed.children[0]

    this.offsetTop = el.getBoundingClientRect().y
    this.style.width = `${el.offsetWidth}px`
    this.style.left = `${el.getBoundingClientRect().x}px`
    this.style.top = `${this.top >= 0 ? this.top : this.offsetTop}px`

    document.addEventListener('scroll', this.listen)
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.listen)
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      const el = this.$refs.fixed.children[0]

      this.style.width = `${el.offsetWidth}px`
      this.style.left = `${el.getBoundingClientRect().x}px`
    },
    listen() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const top = this.top >= 0 ? this.top : this.offsetTop

      if (top >= 0) {
        this.isFixed = this.offsetTop - scrollTop < top
      }
    },
  },
}
</script>

<style lang="scss">
.fixed {
  &__container {
    &--fixed {
      position: fixed;

      &.fixed__container > div {
        position: static;
      }
    }
  }
}
</style>
