<template>
  <div class="join-card-item">
    <h3 class="join-card-item-title">{{ title }}</h3>
    <div class="join-card-item-label">岗位要求：</div>
    <p class="join-card-item-content">
      {{ content }}
    </p>
    <div>
      <app-button class="join-card-item-btn" type="primary" @click="goConnect"
        ><a href="javascript:;">联系我们</a></app-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppJoinCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    goConnect() {
      this.$router.push('/about#connect')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
.join {
  &-card {
    &-item {
      margin-bottom: 48px;
      padding: 28px 32px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.03);
      transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
      &:hover {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      }
      &-title {
        position: relative;
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 12px;
        padding-bottom: 12px;
        &::after {
          content: '';
          position: absolute;
          width: 30px;
          height: 4px;
          background-color: $--color-primary;
          bottom: 0;
          left: 0;
        }
      }
      &-label {
        font-size: 16px;
        color: #666;
        // margin-bottom: 12px;
      }
      &-content {
        font-size: 14px;
        line-height: 1.7;
        color: #666;
        height: 300px;
        white-space: pre-line;
        margin-bottom: 24px;
        overflow: auto;
      }
      &-btn {
        background: #fff;
        color: $--color-primary;
        width: 82px;
        a {
          color: $--color-primary;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .join {
    &-card {
      &-item {
        margin-bottom: 15px;
        padding: 14px 18px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
        &-title {
          font-size: 18px;
          font-weight: 600;
          &::after {
            width: 20px;
            height: 2px;
          }
        }
        &-label {
          font-size: 18px;
          color: #666;
        }
        &-content {
          font-size: 14px;
          line-height: 1.5;
          min-height: 200px;
          white-space: pre-line;
          margin-bottom: 20px;
        }
        &-btn {
          background: #fff;
          color: $--color-primary;
          width: 62px;
          a {
            color: $--color-primary;
          }
        }
      }
    }
  }
}
</style>
