<template>
  <div class="article-list-item">
    <div class="article-list-item-meta">
      <div class="article-list-item-meta-avatar">
        <div
          v-if="tag"
          class="article-list-item-tag"
          :class="`article-list-item-tag-${type}`"
        ></div>
        <slot name="avatar">
          <img
            v-lazy="compressImg(avatar, '190')"
            class="image-radius"
            data-object-fit="cover"
          />
        </slot>
      </div>
      <div class="article-list-item-meta-content">
        <div class="article-list-item-meta-content-pc">
          <p class="article-list-item-meta-time">{{ time }}</p>
          <h4 class="article-list-item-meta-title">
            {{ title }}
          </h4>
          <div class="article-list-item-meta-description">
            {{ description }}
          </div>
        </div>
        <div class="article-list-item-meta-content-mobile">
          <h4 class="article-list-item-meta-title">
            {{ title }}
          </h4>
          <p class="article-list-item-meta-time">{{ time }}</p>
        </div>
      </div>
    </div>
    <div class="article-list-item-action">
      <slot name="action">
        <div class="article-list-item-action-pc">
          <div class="article-list-item-action-content">
            <a-icon type="swap-right" />
          </div>
          <div class="article-list-item-action-edit">
            <span class="delete-btn" @click.stop="onDelete">
              <svg-icon icon-class="shanchu"></svg-icon>
            </span>
            <div
              class="edit-btn-wrapper"
              :style="{ visibility: isEdit ? 'visible' : 'hidden' }"
            >
              <span v-if="isEdit" class="edit-btn" @click.stop="onEdit"
                >编辑</span
              >
            </div>
          </div>
        </div>
        <div class="article-list-item-action-mobile" @click.stop="onDelete">
          <a href="javascript:;" class="mobile-delete-icon">
            <svg-icon icon-class="shanchu"></svg-icon>
          </a>
        </div>
        <div
          v-if="isEdit"
          class="article-list-item-action-mobile article-list-item-action-mobile--top"
        >
          <div class="edit-btn-wrapper" @click.stop="onEdit">
            编辑
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleListItem',
  props: {
    id: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    tag: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDelete() {
      this.$emit('delete', this.id)
    },
    onEdit() {
      this.$emit('edit', this.id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.article-list-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  &:hover {
    box-shadow: 0 0 30px 0 rgba(17, 38, 166, 0.15);
    .article-list-item-action-edit {
      display: flex;
    }
    .article-list-item-action-content {
      display: none;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  &-meta {
    display: flex;
    flex: 1;
    align-items: flex-start;
    &-avatar {
      margin-right: 30px;
      background-color: rgba(84, 84, 84, 0.08);
      width: 190px;
      height: 114px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        @include object-fit(cover);
      }
    }
    &-content {
      flex: 1 0;
      &-pc {
        display: block;
      }
      &-mobile {
        display: none;
      }
    }
    &-time {
      font-size: 12px;
      color: #666;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    &-title {
      margin-bottom: 10px;
      color: #333;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      @include text-ellipsis(1);
    }
    &-description {
      color: #999;
      font-size: 12px;
      line-height: 1.5;
      @include text-ellipsis(2);
    }
  }
  &-action {
    flex: 0 0 auto;
    margin-left: 15px;
    padding: 0;
    i {
      color: #808080;
      font-size: 18px;
    }
    &-content {
      font-size: 14px;
      width: 100px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border-left: 1px solid #ccc;
    }
    &-edit {
      display: none;
      font-size: 14px;
      width: 100px;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .edit-btn {
        display: inline-block;
        width: 50px;
        height: 24px;
        line-height: 24px;
        background-color: #545454;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
      }
      .edit-btn-wrapper {
        border-left: 1px solid #ccc;
        text-align: center;
        width: 100%;
        margin-top: 40px;
        padding: 3px 0;
      }
      .delete-btn {
        width: 24px;
        height: 24px;
        font-size: 12px;
        color: #fff;
        border-radius: 12px;
        background: #f10909;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 22px;
        .svg-icon {
          color: #fff;
        }
      }
    }
    &-pc {
      display: block;
    }
    &-mobile {
      display: none;
    }
  }
  &-tag {
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    color: #fff;
    text-align: center;

    &-info {
      // background-image: url('../../assets/tag-info.png');
      background: url('../../assets/tag-info.png') no-repeat center/cover;
    }
    &-success {
      // background-image: url('../../assets/tag-success.png');
      background: url('../../assets/tag-success.png') no-repeat center/cover;
    }
    &-error {
      // background-image: url('../../assets/tag-error.png');
      background: url('../../assets/tag-error.png') no-repeat center/cover;
    }
  }
}

@media screen and (max-width: 768px) {
  .article-list-item {
    margin-bottom: 18px;
    border-bottom: none;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
    &-meta {
      padding-right: 25px;

      &-avatar {
        margin-right: 10px;
        img {
          width: 105px;
          height: 70px;
        }
      }
      &-time {
        color: #999;
        margin-bottom: 0;
        margin-top: 4px;
      }
      &-title {
        margin-bottom: 0;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        height: 40px;
        @include text-ellipsis(2);
      }
      &-description {
        display: none;
      }
      &-content {
        &-pc {
          display: none;
        }
        &-mobile {
          display: block;
        }
      }
    }
    &-action {
      &-content {
        font-size: 14px;
        width: auto;
        text-align: center;
        height: 30px;
        line-height: 30px;
        border-left: none;
      }
      &-pc {
        display: none;
      }
      &-mobile {
        display: block;
        position: absolute;
        bottom: 6px;
        right: 0;
        .mobile-delete-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f10909;
          color: #fff;
          width: 28px;
          height: 28px;
          border-radius: 14px;
          text-align: center;
          transform: scale(0.5);
          ::v-deep {
            .svg-icon {
              font-size: 14px;
              color: #fff;
            }
          }
        }

        &--top {
          top: 3px;
          right: 7px;
          bottom: auto;
        }

        .edit-btn-wrapper {
          text-align: center;
          display: inline-block;
          padding: 3px;
          background-color: #545454;
          font-size: 12px;
          line-height: 1;
          color: #fff;
        }
      }
    }
    &-tag {
      transform: scale(0.7);
      left: -7px;
      top: -5px;
    }
  }
}
</style>
