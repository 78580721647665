<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :closable="false"
    :keyboard="false"
    :mask-closable="false"
    :width="width"
    class="app-modal"
    :class="customClass"
    :centered="true"
    :z-index="3000"
  >
    <div class="message-model__container tets">
      <div v-if="type !== 'tip'" class="message-model__heading">
        <slot name="header">
          <img v-if="type === 'error'" src="~/assets/delete-bg.png" alt />
          <img v-if="type === 'success'" src="~/assets/audit-bg.png" alt />
          <img v-if="type === 'info'" src="~/assets/audit-bg.png" alt />
          <svg-icon
            v-if="type === 'error'"
            class="message-model__heading-icon"
            icon-class="shanchu"
            style="color:#f10909;"
          ></svg-icon>
          <svg-icon
            v-else
            class="message-model__heading-icon"
            icon-class="shenhe"
            style="color:#5368e7;"
          ></svg-icon>
        </slot>
      </div>
      <div class="message-model__body">
        <h4 v-show="title" class="message-model__title">
          {{ title }}
        </h4>
        <p class="message-model__description" v-html="message"></p>
      </div>
      <div class="message-model__footer">
        <div v-if="type === 'error'" class="message-model__footer__confirm">
          <span class="btn cancel" @click.stop="onCancel">取消</span>
          <a-divider class="line" type="vertical" />
          <span class="btn ok" @click.stop="onOk">确认</span>
        </div>
        <div v-else class="message-model__footer__confirm center-flex">
          <span class="btn ok" @click.stop="onOk">我知道了</span>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'AppMessageModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 480,
    },
  },
  data() {
    return {
      visible: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.visible = newVal
    },
    visible(newVal) {
      this.$emit('input', newVal)
    },
  },
  methods: {
    onCancel() {
      this.visible = false
      this.$emit('cancel')
    },
    onOk() {
      this.visible = false
      this.$emit('ok')
    },
  },
}
</script>

<style lang="scss">
.message-model {
  &__container {
    text-align: center;
  }

  &__heading {
    height: 150px;
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    &-icon {
      font-size: 50px;
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translate(-50%, 0);
    }
  }

  &__title {
    padding: 30px 0 22px;
    font-size: 16px;
    text-align: center;
    color: #000;
  }

  &__description {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    padding: 0 90px;
    text-align: left;
    margin-bottom: 0;

    img {
      max-width: 80%;
    }
  }
  &__footer {
    padding: 36px 0;
    font-size: 16px;
    &__confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        padding: 0 98px;
        cursor: pointer;
      }
      .cancel {
        color: #999;
      }
      .ok {
        color: #5368e7;
      }
      .line {
        color: #c6c6c6;
        height: 16px;
      }
    }
    .center-flex {
      display: flex;
      justify-content: center;
    }
    &__action {
      text-align: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .message-model {
    &__container {
      text-align: center;
    }

    &__heading {
      display: none;
    }

    &__title {
      margin: 0;
      padding: 28px 0 20px;
      font-size: 16px;
      font-weight: 500;
    }

    &__description {
      font-size: 12px;
      padding: 0 24px;
      text-align: left;
    }
    &__footer {
      padding: 30px 0;
      font-size: 12px;
      &__confirm {
        justify-content: space-between;
        .btn {
          padding: 0 24px;
        }
        .cancel {
          color: #999;
        }
        .ok {
          color: #5368e7;
        }
        .line {
          color: #c6c6c6;
          height: 16px;
        }
      }
      &__action {
        text-align: center;
      }
    }
  }

  .app-modal--has-header {
    .message-model__heading {
      display: block;
      height: 75px;
    }
  }
}
</style>
<style lang="scss">
.app-modal {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
}
@media screen and (max-width: 768px) {
  .app-modal {
    width: 100% !important;
    padding: 0 30px;
    .ant-modal-content {
      border-radius: 6px;
    }
  }
}
</style>
