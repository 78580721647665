<template>
  <div class="app-guide-card" @click="goToDetailPage">
    <div class="app-guide-card-line">
      <span class="line-content"></span>
    </div>
    <div class="app-guide-card-title">{{ title }}</div>
    <p class="app-guide-card-desc">{{ content }}</p>
    <div class="app-guide-card-detail">
      <app-button type="primary"
        >查看详情<a-icon type="swap-right"
      /></app-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppGuideCard',
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    goToDetailPage() {
      this.$router.push(`/article-detail/${this.id}?kind=LIBRARY`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables';
.app-guide-card {
  padding: 38px 40px 50px;
  background: #fff;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
  cursor: pointer;
  &:hover {
    background: $--color-primary;
    .app-guide-card {
      &-line {
        .line-content {
          background: #545454;
        }
      }
      &-title {
        color: #fff;
      }
      &-desc {
        color: #fff;
      }
    }
  }
  &-line {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    .line-content {
      width: 36px;
      height: 5px;
      background: $--color-primary;
    }
  }
  &-title {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    color: #000;
    margin-bottom: 20px;
    height: 45px;
    @include text-ellipsis(2);
  }
  &-desc {
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    font-size: 12px;
    line-height: 2;
    color: #808080;
    margin-bottom: 100px;
    height: 72px;
    @include text-ellipsis(3);
  }
  &-detail {
    text-align: left;
    ::v-deep {
      .app-button {
        padding: 0 10px;
        border: solid 0.6px $--color-primary;
        background-color: #fff;
        color: $--color-primary;
      }
    }
  }
}

@media (max-width: 768px) {
  .app-guide-card {
    padding: 15px 0 20px;
    background: transparent;
    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    border-top: 1px solid #666;
    &:hover {
      background: transparent;
      .app-guide-card {
        &-title {
          color: #fff;
        }
        &-desc {
          color: #fff;
        }
      }
    }

    &-line {
      display: none;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 17px;
      color: #fff;
      margin-bottom: 7px;
      height: 17px;
      @include text-ellipsis(1);
    }
    &-desc {
      line-height: 1.4;
      color: #eee;
      margin-bottom: 15px;
      height: 32px;
      @include text-ellipsis(2);
    }
    &-detail {
      text-align: left;
      ::v-deep {
        .app-button {
          width: 80px;
          height: 24px;
          line-height: 22px;
          padding: 0 5px;
          border: 1px solid #fff;
          background-color: transparent;
          color: #fff;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
