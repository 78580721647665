import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import * as timeago from 'timeago.js'
import { AUTHTYPE } from '@/utilities/constants'
import userIconMan from '@/assets/user-man.png'
import userIconWoman from '@/assets/user-woman.png'
import defaultUser from '@/assets/default-user.png'

dayjs.locale('zh-cn')

export default {
  methods: {
    dateFormat(date, format = 'YYYY-MM-DD') {
      // YYYY-MM-DD HH:mm:ss
      return (date && dayjs(date).format(format)) || ''
    },
    timeAgo(date) {
      return timeago && timeago.format(date, 'zh_CN')
    },
    toFixed(value, num = 2) {
      return Number(value).toFixed(num) || 0
    },
    addDomEventListener(target, eventName, callback) {
      target.addEventListener(eventName, callback)
      return function() {
        target.removeEventListener(eventName, callback)
      }
    },
    isAuthDoctor(role) {
      if (!_.isArray(role)) {
        return false
      }
      return (
        _.intersection(role, [AUTHTYPE.DOCTOR, AUTHTYPE.DOCTOR_STUDENT])
          .length > 0
      )
    },
    compressImg(url, width) {
      return url
        ? `${url}?x-oss-process=image/auto-orient,1/interlace,1/resize,m_lfit,w_${width ||
            200}/quality,q_100`
        : ''
    },
    getUserIcon(userInfo) {
      if (!userInfo) {
        return defaultUser
      }
      return userInfo.gender === 'MALE'
        ? userIconMan
        : userInfo.gender === 'FEMALE'
        ? userIconWoman
        : defaultUser
    },
  },
}
