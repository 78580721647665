<template>
  <a-upload
    class="single-uploader"
    :headers="{ Authorization: `Bearer ${token}` }"
    :show-upload-list="isShowFileList"
    list-type="picture"
    :custom-request="uploadOss"
    :file-list="controlFileList"
    :remove="handleRemove"
    :before-upload="handleBeforeUpload"
    :support-server-render="true"
    :multiple="false"
    :accept="accept"
    :disabled="disabled"
    @change="handleChange"
    @success="handleSuccess"
  >
    <slot>
      <template v-if="!isCropper || (!isShowCropper && isCropper)">
        <div v-if="type === 'avatar'" class="avatar">
          <img v-if="imgUrl" v-lazy="imgUrl" fit="cover" class="view-img" />
          <div v-else>
            <svg-icon
              icon-class="add-fill"
              style="color:#2c88fc;font-size:20px;"
            ></svg-icon>
            <div class="avatar-text">点击上传</div>
          </div>
        </div>
        <span v-else-if="type === 'diy'"></span>
        <span v-else>
          <app-button type="upload" :disabled="disabled">
            <div v-if="!imgUrl || isShowFileList" class="upload-button-inner">
              <svg-icon
                icon-class="add-fill"
                style="color:#2c88fc;font-size:18px;"
              ></svg-icon>
              <span>&nbsp;点击上传</span>
            </div>
            <div
              v-else-if="imgUrl && !isShowFileList"
              class="upload-button-inner"
            >
              <span>{{ filename }}</span>
            </div>
          </app-button>
        </span>
      </template>
      <div
        v-show="imgUrl && isCropper && isShowCropper"
        id="img-cropper-container"
        class="upload-cropper"
        @click.stop="() => {}"
      >
        <img :src="imgUrl" alt="" />
        <div class="upload-cropper__actions">
          <app-button @click="isShowCropper = false">取消</app-button>
          <app-button type="primary" @click="handleCropperClick"
            >确定裁切</app-button
          >
        </div>
      </div>
    </slot>
  </a-upload>
</template>

<script>
import _ from 'lodash'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import uploadOss from '@/services/upload'
import { getToken } from '@/services/token'

const isLocalOss =
  process.env.UPLOAD_HOST && process.env.UPLOAD_HOST.includes('jianying')

const noop = () => {}

export default {
  name: 'SingleUpload',
  props: {
    // avatar 头像 base 普通按钮
    type: {
      type: String,
      default: 'base',
    },
    fileList: {
      type: Array,
      default() {
        return []
      },
    },
    fileType: {
      type: String,
      default: 'image',
    },
    isShowFileList: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCropper: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: !isLocalOss ? 102400 : 2 * 1024, // kb
    },
    beforeUpload: {
      type: Function,
      default: noop,
    },
  },
  data() {
    return {
      token: getToken(),
      imgUrl: '',
      loadingInstance: null,
      host: '',
      keyPrefix: '',
      assetsPrefix: '',
      certificate: {},
      fileKey: '',
      filename: '',
      controlFileList: [],
      isCanUpload: true,
      isShowCropper: false,
      cropper: null,
    }
  },
  computed: {
    accept() {
      const supportTypes = {
        image: 'image/*',
        video: 'video/mp4,.mp4',
      }
      return supportTypes[this.fileType] || `${this.fileType}` || '*'
    },
  },
  watch: {
    fileList: {
      handler(newValue) {
        this.assignURL(newValue)

        if (
          (newValue.length && !this.controlFileList.length) ||
          (!newValue.length && this.controlFileList.length)
        ) {
          this.controlFileList = newValue
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.controlFileList = []
  },
  methods: {
    uploadOss: uploadOss.upload,
    checkSize(file) {
      return this.maxSize * 1024 > file.size
    },
    handleBeforeUpload(file) {
      if (this.fileType === 'video' && !_.includes(file.type, 'mp4')) {
        this.$message.error('请选择mp4类型的视频')
        return false
      }

      if (this.isShowFileList && this.fileList.length > 0) {
        this.isCanUpload = false
        this.$message.error(`请先移除已上传的文件，再次尝试进行上传。`)
        return false
      }

      if (!this.checkSize(file)) {
        this.isCanUpload = false
        this.$message.error(
          `文件体积不能超过 ${
            this.maxSize < 1000
              ? this.maxSize + 'KB'
              : this.maxSize / 1024 + 'MB'
          }.`
        )
        return false
      }

      this.isCanUpload = true
      this.loadingInstance = this.$appLoading()
    },
    handleRemove(file, a) {
      this.$emit('remove')
      if (this.disabled) {
        return false
      }

      const fileList = _.filter(
        this.controlFileList,
        item => item.uid !== file.uid
      )

      this.$emit('change', fileList)
      this.controlFileList = fileList
      this.$emit('update:fileList', fileList)
    },
    handleSuccess(file) {
      _.assign(file, file.response)
      let fileList = _.slice(this.fileList)

      if (!file.url) {
        this.$message.error('图片地址错误，请重新上传。')
        return
      }

      fileList.push(file)

      if (fileList.length > 1) {
        fileList = _.tail(fileList)
      }

      this.imgUrl = file.url
      const filename = _.last(_.split(file.originalName, '/'))
      this.filename = filename

      this.$emit('change', fileList)
      this.$emit('update:fileList', fileList)

      this.isShowCropper = true
      this.$nextTick(() => {
        const singleUploadEl = document.getElementById('img-cropper-container')
        if (singleUploadEl) {
          const imgEl = singleUploadEl.getElementsByTagName('img')[0]

          if (this.cropper) {
            this.cropper.replace(this.imgUrl)
          } else {
            this.cropper = new Cropper(imgEl, {
              aspectRatio: 1,
            })
          }
        }
      })
    },
    handleChange({ file, fileList }) {
      if (this.isCanUpload) {
        this.controlFileList = fileList
      } else {
        this.controlFileList = _.filter(fileList, item => item.uid !== file.uid)
      }

      if (file.status === 'done') {
        this.handleSuccess(file)
        if (this.loadingInstance) {
          this.loadingInstance.close()
        }
      } else if (file.status === 'error') {
        this.$message.error('上传失败')
        if (this.loadingInstance) {
          this.loadingInstance.close()
        }
      }
    },
    assignURL(target) {
      _.forEach(target, item => {
        if (!item.url) {
          _.assign(item, {
            url: item.path,
          })
        }

        if (!item.uid) {
          _.assign(item, {
            uid: -Date.now(),
          })
        }

        if (!item.name) {
          _.assign(item, {
            name: item.originalName,
          })
        }
      })
      const file = _.last(target)
      this.imgUrl = file && file.url
      this.filename = file && (file.originalName || file.name)
    },
    handleCropperClick() {
      if (!this.cropper) {
        return
      }

      this.loadingInstance = this.$appLoading()

      this.cropper
        .getCroppedCanvas({
          width: 250,
          height: 250,
          minWidth: 250,
          minHeight: 250,
          maxWidth: 4999,
          maxHeight: 4999,
          fillColor: '#fff',
          imageSmoothingEnabled: false,
          imageSmoothingQuality: 'high',
        })
        .toBlob(blob => {
          this.uploadOss({
            file: {
              name: this.filename,
              blob,
            },
          })
            .then(file => {
              let fileList = _.slice(this.fileList)

              if (!file.url) {
                this.$message.error('图片地址错误，请重新上传。')
                return
              }

              fileList.push(file)

              if (fileList.length > 1) {
                fileList = _.tail(fileList)
              }

              this.imgUrl = file.url
              const filename = _.last(_.split(file.originalName, '/'))
              this.filename = filename
              this.$emit('change', fileList)
              this.$emit('update:fileList', fileList)
              this.isShowCropper = false

              this.resetCropper()

              if (this.loadingInstance) {
                this.loadingInstance.close()
              }
            })
            .catch(err => {
              this.$message.error(err.message)
              this.isShowCropper = false

              if (this.loadingInstance) {
                this.loadingInstance.close()
              }
            })
        })
    },
    resetCropper() {
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.single-uploader {
  display: inline-block;
  .view-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .uploader-icon {
    font-size: 25px;
    color: #666;
    text-align: center;
  }
  .avatar {
    width: 96px;
    height: 96px;
    cursor: pointer;
    border-radius: 3px;
    background-color: #eff1f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    &-text {
      font-size: 12px;
      color: #999;
      margin-top: 7px;
    }
  }
  .upload-button-inner {
    .svg-icon {
      top: 1px;
      position: relative;
    }
  }
}

.single-uploader ::v-deep .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

::v-deep .ant-upload-list-item-name {
  color: inherit;
}
</style>
<style lang="scss">
.upload-cropper {
  position: relative;
  padding-bottom: 40px;

  &__actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  img {
    max-width: 100%;
  }
}
</style>
