import request from './request'

export default {
  fetchWeiboList(params) {
    return request.get(`/weibo`, { params })
  },
  fetchMyWeiboList(params) {
    return request.get(`/weibo`, { params })
  },
  fetchWeiboInfo(id, params) {
    return request.get(`/weibo/${id}`, { params })
  },
  fetchMyStarWeiboList(params) {
    return request.get(`/user/stars/weibo`, { params })
  },
  addWeiboInfo(payload) {
    return request.post(`/weibo`, payload)
  },
  updateWeiboThumbsUp(id, payload) {
    return request.post(`/weibo/${id}/thumbs-up`, payload)
  },
  deleteWeibo(id) {
    return request.delete(`/weibo/${id}`)
  },
  deleteComment(weiboID, commentID) {
    return request.delete(`/weibo/${weiboID}/comments/${commentID}`)
  },
  fetchCommentList(weiboID, params) {
    return request.get(`/weibo/${weiboID}/comments`, { params })
  },
  addComment(id, payload) {
    return request.post(`/weibo/${id}/comments`, payload)
  },
  updateCommentThumbsUpByWeibo(weiboID, commentID, payload) {
    return request.post(
      `/weibo/${weiboID}/comments/${commentID}/thumbs-up`,
      payload
    )
  },
  updateWeiboStarStatus(weiboID, payload) {
    return request.post(`/weibo/${weiboID}/star`, payload)
  },
}
